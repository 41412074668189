import { useToasts } from 'react-toast-notifications';
import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Button, Table, TableRow, TableHead } from './styles'
import { Container, Content, Card } from '../../components/styles'
import BarLeft from '../../components/BarLeft'
import BarTop from '../../components/BarTop'
import Spinner from '../../components/Spinner'
import { UserContext } from '../../contexts/userContext';
import { getAllUserTimes, postUserTimes } from '../../services/apiSaoLuis/times'
import moment from 'moment'
import { ModalContext } from '../../contexts/modalContext';
import TimeRequestModal from '../../components/Modal/TimeRequestModal';
moment.updateLocale('pt-br', {
  months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_'),
  weekdaysShort: 'Domingo_Segunda_Terça_Quarta_Quinta_Sexta_Sábado'.split('_'),
});

const Index = () => {

  const { handleModal } = useContext(ModalContext);
  const { getUser, user } = useContext(UserContext);
  const { addToast } = useToasts();
  const [userTimes, setUserTimes] = useState([])
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null
  })

  useEffect(() => {
    if ("geolocation" in navigator) {
      console.log("Available");
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          latitude: position.coords.latitude || null,
          longitude: position.coords.longitude || null
        })
      });
    } else {
      console.log("Not Available");
    }
  }, [])

  useEffect(() => {
    !user && getUser()
    listUserTimes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const listUserTimes = async () => {
    setLoading(true)
    let userTimes = await getAllUserTimes()
    setUserTimes(userTimes.data)
    setLoading(false)
  }

  const handlePostUserTimes = async () => {
    setLoading(true);
    let userTime = {
      dateTime: moment().format(),
      location
    }
    await postUserTimes(
      userTime,
      onSuccess => {
        getUser()
        listUserTimes()
        addToast(onSuccess.message, { appearance: 'success', autoDismiss: true })
      },
      onFail => {
        addToast(onFail.message, { appearance: 'error', autoDismiss: true })
      }
    )
    setLoading(false);
  }

  const RenderTableData = () => {
    if (userTimes === undefined)
      return null
    else {
      return userTimes.map((time, index) => {
        return (
          <TableRow
            key={index}
            onClick={() => {
              if (
                moment.utc(time.date).format("DD/MM/yyyy")
                === moment.utc().format("DD/MM/yyyy")
              ) {
                addToast('Só é possivel solicitar retificação neste ponto a partir de amanhã', { appearance: 'error', autoDismiss: true })
              } else {
                handleModal(<TimeRequestModal listUserTimes={listUserTimes} time={time} type={'new'} />)
              }
            }
            }
          >
            <td style={{ flex: 2, alignSelf: 'stretch', fontSize: 11 }}>
              {moment.utc(time.date).format("ddd, ")}
              {moment.utc(time.date).format("DD [de] MMM, yyyy")}
            </td>
            <td style={{ flex: 1, fontSize: 11 }}>{time.times[0]?.in}</td>
            <td style={{ flex: 1.2, fontSize: 11 }}>{time.times[1]?.out}</td>
            <td style={{ flex: 1.2, fontSize: 11 }}>{time.times[2]?.in}</td>
            <td style={{ flex: 0.6, fontSize: 11 }}>{time.times[3]?.out}</td>
            <td style={{ flex: 0.6, fontSize: 11 }}>{time.totalTimes}</td>
          </TableRow>
        )
      })
    }
  }

  return (
    <Container>
      <BarLeft menuSelected='Ponto Eletrônico' subMenuSelected='Registro de ponto' active='Ponto Eletrônico Online' />
      <BarTop title='Registro de ponto' />
      <Content>
        {loading
          ? <Spinner /> :
        <Card>
          <Row style={{ flex: 1 }}>
              <Row style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                <Col>
                </Col>
                <Row>
                  <Col>
                    <Button
                      onDoubleClick={handlePostUserTimes}
                      bgColor={user && user.isWorking ? '#B87F7F' : '#87B87F'}
                      disabled={loading}
                    >
                      {user && user.isWorking ? 'Registrar Saída' : 'Registrar Entrada'}
                    </Button>
                  </Col>
                </Row>
              </Row>

          </Row>
          <Row>
            <Table>
              <TableHead>
                <tr>
                  <th>Data</th>
                  <th>Entrada 1</th>
                  <th>Saída 1</th>
                  <th>Entrada 2</th>
                  <th>Saída 2</th>
                  <th>Total de Horas</th>
                </tr>
              </TableHead>
              <tbody>
                <RenderTableData />
              </tbody>
            </Table >
          </Row>
          </Card>
        }
      </Content>
    </Container >

  )
}

export default Index;




/*
<div key={balanceRuler._id}>
        <Title style={{ color: "black" }}>{userRuler.title}</Title>
        <RulesRow>
          <Percent>{parseInt(balanceRuler.total * -1 / balance.revenuesTotal * 100) + "%"}</Percent>
          <Percent>~</Percent>
          <Percent>{userRuler.percent + "%"}</Percent>
        </RulesRow>

        <RulesRow>
          <Value>
            {
              (balanceRuler.total * -1).toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
          <Value>
            {
              (userRuler.percent * balance.revenuesTotal / 100)
                .toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
        </RulesRow>
      </div>
*/