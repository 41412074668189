import React from 'react'
import styled from 'styled-components'
import { COLORS } from './styles';
import searchIcon from '../assets/searchIcon.svg'

const index = ({ placeholder, value, onChange, type }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
      <Img src={searchIcon} />
      <Input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}

export default index


export const Input = styled.input`
color: #A0A1B6;
width: 18vw;
font-weight: 400;
font-size: 11pt;
border: 0;
padding: 10px 0 10px 35px;
border-radius: 4px;
background: ${COLORS.BACKGROUND};
:focus {
  outline: none;
  box-shadow: 0 0 .2pt 1.5pt #2D323E;
}
  @media(max-width: 1440px) {
      width: 25vw;  
  }
  @media(max-width: 1024px) {
      width: 30vw;  
  }
  @media(max-width: 768px) {
      width: 40vw;
  }
  @media(max-width: 425px) {
      width: 90vw;
  }
`

export const Img = styled.img`
  position: absolute;
  height: 18px;
  margin: 10px 0 0 8px;

`