import React from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "../../contexts/modalContext";
import { Container } from './styles.js'

const Modal = () => {
  let { modalContent, handleModal, modal } = React.useContext(ModalContext);
  const ref = React.createRef();

  if (modal) {
    return ReactDOM.createPortal(
      <Container ref={ref} onMouseDown={e => ((e.target === ref.current) ? handleModal() : null)}>
        <div >
          {modalContent}
        </div >
      </Container >,
      document.querySelector("#modal-root")
    );
  } else return null;
};

export default Modal;