import { useToasts } from 'react-toast-notifications';
import React, { useContext, useEffect, useState } from 'react'
import { Row, UserButton, Text, Table, TextHeader, Col } from './styles'
import { Container, Content, Card, COLORS } from '../../components/styles'
import BarLeft from '../../components/BarLeft'
import BarTop from '../../components/BarTop'
import { UserContext } from '../../contexts/userContext';
import {
  getAllTimeRequests,
  getUserTimeRequests,
  updateTimeRequest
} from '../../services/apiSaoLuis/timeRequest';
import InputSelect from '../../components/InputSelect';
import Spinner from '../../components/Spinner'
import moment from 'moment';

const Index = () => {
  const { getUser, user } = useContext(UserContext);
  const { addToast } = useToasts();
  const [timeRequests, setTimeRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('todos');

  useEffect(() => {
    !user && getUser()
    if (user !== null) {
      listAllTimesRequests()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, status])

  const handleUpdateTimeRequest = async (item, status) => {
    await updateTimeRequest(
      item,
      status,
      onSuccess => {
        listAllTimesRequests();
        addToast(onSuccess.message,
          { appearance: 'success', autoDismiss: true }
        );
      });
  };

  const listAllTimesRequests = async () => {
    setLoading(true)
    if (user && user.role === 'ADMIN') {
      await getAllTimeRequests(status.toLowerCase(), onSuccess => {
        setTimeRequests(onSuccess.data)
      })
    } else {
      await getUserTimeRequests(status.toLowerCase(), onSuccess => {
        setTimeRequests(onSuccess.data)
      })
    }

    setLoading(false)
  }

  const RenderTableData = () => {
    return timeRequests && timeRequests.map((item, index) => {
      return (
        <Col
          key={index}
          style={{
          marginBottom: 20,
          borderBottomColor: COLORS.BACKGROUND,
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
        }}>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col style={{ width: 130 }}>
              <Row><TextHeader>Data</TextHeader></Row>
              <Row><Text><span style={{ fontSize: '1.3em', fontWeight: 'bold' }}>{moment.utc(item.date).format("ddd")}</span>{moment.utc(item?.date).format(", D, MMMM, YYYY")}</Text></Row>
            </Col>
            <Col style={{ width: 250 }}>
              <Row><TextHeader>Nome do colaborador</TextHeader></Row>
              <Row><Text>{item?.user.fullName}</Text></Row>
            </Col>
            <Col style={{ width: 110 }}>
              <Row><TextHeader>CPF</TextHeader></Row>
              <Row><Text>{item?.user.cpf}</Text></Row>
            </Col>
            <Col style={{ width: 80 }}>
              <Row><TextHeader>Entrada</TextHeader></Row>
              <Row><Text>{item.times[0]?.in}</Text></Row>
            </Col>
            <Col style={{ width: 150 }}>
              <Row><TextHeader>Inicio de intervalo</TextHeader></Row>
              <Row><Text>{item.times[1]?.out}</Text></Row>
            </Col>
            <Col style={{ width: 150 }}>
              <Row><TextHeader>Retorno de Intervalo</TextHeader></Row>
              <Row><Text>{item.times[2]?.in}</Text></Row>
            </Col>
            <Col style={{ width: 80 }}>
              <Row><TextHeader>Saída</TextHeader></Row>
              <Row><Text>{item.times[3]?.out}</Text></Row>
            </Col>
            <Col>
              {item.status !== 'pendente' || user.role !== 'ADMIN' ? null :
                <UserButton
                  onDoubleClick={() => { handleUpdateTimeRequest(item, 'aprovado') }} bgColor={COLORS.GREEN}>
                  Aceitar
                </UserButton>
              }
            </Col>
          </Row>
          <Row style={{ position: 'relative' }}>
            <Col style={{ width: 85 }}>
              <Row><TextHeader>Status</TextHeader></Row>
              <Row>
                <Text
                  style={
                    item.status === 'aprovado'
                      ? { color: COLORS.GREEN, fontWeight: 'bold' }
                      : null
                        ||
                        item.status === 'recusado'
                        ? { color: COLORS.ORANGE, fontWeight: 'bold' }
                        : null
                  }
                >{item.status[0].toUpperCase() + item.status.substr(1)}</Text></Row>
            </Col>
            <Col style={{ width: 165 }}>
              <Row>
                <TextHeader>Tipo de solicitação</TextHeader>
              </Row>
              <Row>
                <Text>{item.type}</Text>
              </Row>
            </Col>
            <Col style={{ alignContent: 'flex-start' }}>
              <Row>
                <TextHeader>Motivo</TextHeader>
              </Row>
              <Row>
                <Text>{item.motivation}</Text>
              </Row>
            </Col>
            {item.status !== 'pendente' || user.role !== 'ADMIN' ? null :
              <Col style={{ position: 'absolute', right: 0 }}>
                <UserButton
                  onDoubleClick={() => {
                    handleUpdateTimeRequest(item, 'recusado')
                  }}
                  bgColor={COLORS.ORANGE}>
                  Recusar
                </UserButton>
            </Col>
            }
          </Row>
        </Col>
      )
    })
  }

  return (
    <Container>
      <BarLeft menuSelected='Ponto Eletrônico' subMenuSelected='Solicitações de ponto' />
      <BarTop title='Solicitações de pontos' />
      <Content>
          {loading
            ? <Spinner />
            : <Card>
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Col>
              </Col>
              <Col>
                <InputSelect
                  value={status}
                  options={['Todos', 'Pendente', 'Aprovado']}
                  onChange={(e) => setStatus(e.target.value)}
                />
              </Col>
              </Row>
              <Table>
                <RenderTableData />
              </Table >

            </Card>
          }
      </Content>
    </Container >

  )
}

export default Index;




/*
<div key={balanceRuler._id}>
        <Title style={{ color: "black" }}>{userRuler.title}</Title>
        <RulesRow>
          <Percent>{parseInt(balanceRuler.total * -1 / balance.revenuesTotal * 100) + "%"}</Percent>
          <Percent>~</Percent>
          <Percent>{userRuler.percent + "%"}</Percent>
        </RulesRow>

        <RulesRow>
          <Value>
            {
              (balanceRuler.total * -1).toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
          <Value>
            {
              (userRuler.percent * balance.revenuesTotal / 100)
                .toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
        </RulesRow>
      </div>
*/