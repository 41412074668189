import React from 'react'
import styled from 'styled-components'
import { COLORS } from './styles';
import filterIcon from '../assets/filterIcon.svg'

const index = ({ value, onChange, options }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
      <Img src={filterIcon} />
      <Select
        value={value}
        onChange={onChange}
      >
        {options.map((item) => (<option>{item}</option >))}
      </Select>
    </div>
  )
}

export default index


export const Select = styled.select`
color: #A0A1B6;
font-weight: 400;
font-size: 11pt;
border: 0;
padding: 10px 20px 10px 35px;
border-radius: 4px;
background: ${COLORS.BACKGROUND};
:focus {
  outline: none;
  box-shadow: 0 0 .2pt 1.5pt #2D323E;
}
`

export const Img = styled.img`
  position: absolute;
  height: 18px;
  margin: 10px 0 0 8px;

`