import styled from 'styled-components'
import { COLORS } from '../../styles'

export const Container = styled.div`
background-color: ${COLORS.WHITE};
width: 650px;
padding: 5px;
border-radius: 5px;
z-index: 1000;
flex-direction: column;
  @media(max-width: 1024px) {  
  width: 100%;
}
`
export const Form = styled.form`
display: flex;
flex-direction: column;
padding: 0 10px;
`
export const Title = styled.h1`
padding: 10px;
font-weight: 500;
font-size: 13pt;
color: #919098;
`
export const FormGroup = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
`
export const Col = styled.div`
flex: 1;
flex-direction: column;
`

export const Button = styled.button`
width: 140px;
color: ${COLORS.WHITE};
margin: 10px 0 0 10px;
border:none;
cursor: pointer;
height: 38px;
background: ${props => props.bgColor};
border-radius: 4px;
font-weight: 500;
font-size: 12px;
:hover{
  background: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
}
`

export const ButtonSum = styled.button`
display: flex;
cursor: pointer;
width: 38px;
height: 38px;
border:none;
border-radius: 4px;
font-weight: 500;
font-size: 11px;
line-height: 20pt;
align-items: center;
justify-content: center;
margin: 18px 0 18px 5px;
&:hover{
  color: black;
  background: ${props => props.color};
}
`


export const CloseButton = styled.a`
border:none;
cursor: pointer;
color: #919098;
padding: 10px;
font-weight: 800;
font-size: 16px;
&:hover{
  color: black;
}
`
export const Label = styled.label`
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 14px;
`
export const Select = styled.select`
display: flex;
color: black;
font-weight: 400;
font-size: 11pt;
border: 0;
padding: 9px 8px;
border-radius: 4px;
background: ${COLORS.BACKGROUND};
&:focus {
  background: ${COLORS.GREY};
  outline: 0;
}
`
export const Input = styled.input`
display: flex;
width: 100%;
color: black;
font-weight: 400;
font-size: 11pt;
border: 0;
padding: 10px 8px;
border-radius: 4px;
background: ${COLORS.BACKGROUND};
&:focus {
  background: ${COLORS.GREY};
  outline: 0;
}
`