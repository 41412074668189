import React from 'react'
import { Container, Title, Value, Img, Row, Col } from './styles'
import { useHistory } from "react-router-dom";
import userResumeIcon from '../../assets/arrowResumeIcon.svg'

const Resume = ({ title, value, link, icon }) => {
  let history = useHistory();
  const { from } = { from: { pathname: link } };

  return (
    <Container style={{ position: 'relative' }}>
      <Row>
        <Col>
          <Img src={icon} />
        </Col>
        <Col>
          <Title>{title}</Title>
          <Value>{value}</Value>
        </Col>
        {link ?
          <Col>
            <Img
              onClick={() => history.replace(from)}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                bottom: 5,
                right: 3,
                height: 25
              }}
              src={userResumeIcon}
            />
          </Col>
          : null
        }

      </Row>
    </Container>
  )
}

export default Resume

/**
 *  <Row style={{ backgroundColor: '#FAFAFA', borderTop: '1px solid #E4E4E4', justifyContent: 'space-between' }}>
        <Col>
          <SubTitle>{subtitle} {subvalue}</SubTitle>
        </Col>
        <Col style={{ alignSelf: 'center' }}>
          <Img src={icon} onClick={() => history.replace(from)} />
        </Col>
      </Row>
 *
 *
 *
 */