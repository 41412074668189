import { useToasts } from 'react-toast-notifications';
import React, { useState, useContext } from "react";
import { Container, Title, Input, Form, FormGroup, Label, Col, Button, CloseButton, Select } from './styles.js'
import { ModalContext } from "../../../contexts/modalContext";
//import { UserContext } from "../../../contexts/userContext";
//import Spinner from '../../Spinner'
import { postUser } from '../../../services/apiSaoLuis/user'
import { cpfMask, phoneMask } from '../../../services/Utils'

const UserModal = ({ listUsers, type, user }) => {
  let { handleModal } = useContext(ModalContext);
  const { addToast } = useToasts();
  // eslint-disable-next-line no-unused-vars
  const [_id] = useState(type === 'new' ? '' : user._id);
  const [fullName, setFullName] = useState(type === 'new' ? '' : user.fullName);
  const [cpf, setCpf] = useState(cpfMask(type === 'new' ? '' : user.cpf));
  const [email, setEmail] = useState(type === 'new' ? '' : user.email);
  const [pis, setPis] = useState(type === 'new' ? '' : user.pis);
  const [phone, setPhone] = useState(type === 'new' ? '' : phoneMask(user.phone));
  const [company, setCompany] = useState(type === 'new' ? 'Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0001-45' : user.company);
  const [role, setRole] = useState(type === 'new' ? "PUBLIC" : user.role);
  const [password, setPassword] = useState(type === 'new' ? '' : null);

  const onSave = async () => {
    if (fullName === '' || cpf === '' || email === '' || phone === '' || password === '') {
      addToast('Preencha tdos os campos!', { appearance: 'error', autoDismiss: true })
    } else {
      await postUser(fullName, cpf, email, pis, phone, company, role, password,
        onSuccess => {
          addToast(onSuccess.message, { appearance: 'success', autoDismiss: true })
          listUsers()
        },
        onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
      )
    }
  }


  const clearUserData = () => {
    setFullName('')
    setCpf('')
    setEmail('')
    setPhone('')
    setCompany('Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0001-45')
    setRole('PUBLIC')
    setPassword('')
  }

  return (
    < Container>
      <FormGroup style={{ justifyContent: "space-between" }}>

        {type === 'edit'
          ? <Title>Editar Colaborador</Title>
          : <Title>Novo Colaborador</Title>
        }
        <CloseButton onClick={() => handleModal()}> X </CloseButton>
      </FormGroup>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
          <Col>
            <Label>Nome Completo</Label>
            <Input type='text' value={fullName} onChange={(e) => setFullName(e.target.value)} />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col>
            <Label>Cpf</Label>
            <Input type='text' value={cpf} onChange={(e) => setCpf(cpfMask(e.target.value))} />
          </Col>
          <Col>
            <Label>Telefone</Label>
            <Input type='text' value={phone} onChange={(e) => setPhone(phoneMask(e.target.value))} />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col>
            <Label>Email</Label>
            <Input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
          </Col>
          <Col>
            <Label>Pis</Label>
            <Input type='text' value={pis} maxLength={12} onChange={(e) => setPis(e.target.value)} />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col>
            <Label>Senha</Label>
            <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
          </Col>
          <Col>
            <Label>Nível de Acesso</Label>
            <Select value={role} onChange={(e) => setRole(e.target.value)}>
              < option >PUBLIC</option >
              < option >ADMIN</option >
            </Select>
          </Col>
        </FormGroup>
        <FormGroup>
          <Col>
            <Label>Vínculo</Label>
            <Select value={company} onChange={(e) => setCompany(e.target.value)}>
              < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0001-45</option >
              < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0001-39</option >
              < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0005-79</option >
              < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0004-81</option >
              < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0003-09</option >
              < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0005-62</option >
              < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0006-50</option >
              < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0002-10</option >
              < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0002-26</option >
              < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0003-07</option >
              < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0007-24</option >
              < option >Funerária São Luis - Valdemar Targino de Moura - 34.459.988/0001-73</option >
            </Select>
          </Col>
        </FormGroup>
        <FormGroup>
          <div><Button
            bgColor={"#1C3E7E"}
              onClick={() => clearUserData()} >{"Limpar"}
            </Button>
              <Button
              bgColor={"#87B87F"}
              onDoubleClick={() => onSave()} >{"Salvar"}
              </Button>
          </div>
        </FormGroup>
      </Form>

    </Container >
  )
};

export default UserModal;


/***
 *   const onUpdate = async () => {
    let data = !password
      ? { _id, fullName, cpf, email, phone, company, role }
      : { _id, fullName, cpf, email, phone, company, role, password };

    await putUser(data,
      onSuccess => {
        addToast(
          onSuccess.message,
          {
            appearance: 'success',
            autoDismiss: true
          })
        listUsers()
        handleModal()
      },
      onFail => {
        addToast(
          onFail.message,
          {
            appearance: 'error',
            autoDismiss: true
          })
      }
    )
  }

 */