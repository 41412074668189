import { useToasts } from 'react-toast-notifications';
import React, { useState, useContext } from "react";
import { Container, Title, Input, Form, FormGroup, Label, Col, Button, CloseButton } from './styles.js'
import { ModalContext } from "../../../contexts/modalContext";
import { postNewUserTime } from '../../../services/apiSaoLuis/times'
import moment from 'moment'

const TimeModal = ({ listUserTimes, type, time, id }) => {
  let { handleModal } = useContext(ModalContext);
  const { addToast } = useToasts();

  const [userTime, setUserTime] = useState({
    date: moment.utc().format("YYYY-MM-DD"),
    times: [
      {
        in: '00:00',
        out: '00:00'
      },
      {
        in: '00:00',
        out: '00:00'
      }
    ],
    userId: id
  });

  const handleNewOtherTimes = async (time) => {
    await postNewUserTime(
      time,
      // eslint-disable-next-line no-sequences
      onSuccess => (listUserTimes(), addToast(onSuccess.message, { appearance: 'success', autoDismiss: true })),
      onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
    )
  };

  const updateDataChanged = e => {
    let newUserTime = { ...userTime };
    newUserTime.date = e.target.value
    setUserTime(newUserTime);
  };

  const handleInputCheck = e => {
    let newUserTime = { ...userTime };
    switch (e.target.id) {
      case 'Falta':
        document.getElementById('Aviso Prévio').checked = false;
        document.getElementById('Feriado').checked = false;
        document.getElementById('Folga').checked = false;
        document.getElementById('Atestado').checked = false;
        newUserTime.times = [
          {
            in: 'Falta',
            out: 'Falta'
          },
          {
            in: 'Falta',
            out: 'Falta'
          }
        ]
        //setUserTime(newUserTime)
        setUserTime(newUserTime)
        break;
      case 'Aviso Prévio':
        document.getElementById('Falta').checked = false;
        document.getElementById('Feriado').checked = false;
        document.getElementById('Folga').checked = false;
        document.getElementById('Atestado').checked = false;
        newUserTime.times = [
          {
            in: 'Aviso Prévio',
            out: 'Aviso Prévio'
          },
          {
            in: 'Aviso Prévio',
            out: 'Aviso Prévio'
          }
        ]
        //setUserTime(newUserTime)
        setUserTime(newUserTime)
        break;
      case 'Feriado':
        document.getElementById('Aviso Prévio').checked = false;
        document.getElementById('Falta').checked = false;
        document.getElementById('Folga').checked = false;
        document.getElementById('Atestado').checked = false;
        newUserTime.times = [
          {
            in: 'Feriado',
            out: 'Feriado'
          },
          {
            in: 'Feriado',
            out: 'Feriado'
          }
        ]
        //setUserTime(newUserTime)
        setUserTime(newUserTime)
        break;
      case 'Folga':
        document.getElementById('Aviso Prévio').checked = false;
        document.getElementById('Feriado').checked = false;
        document.getElementById('Falta').checked = false;
        document.getElementById('Atestado').checked = false;
        newUserTime.times = [
          {
            in: 'Folga',
            out: 'Folga'
          },
          {
            in: 'Folga',
            out: 'Folga'
          }
        ]
        //setUserTime(newUserTime)
        setUserTime(newUserTime)
        break;
      case 'Atestado':
        document.getElementById('Aviso Prévio').checked = false;
        document.getElementById('Feriado').checked = false;
        document.getElementById('Folga').checked = false;
        document.getElementById('Falta').checked = false;
        newUserTime.times = [
          {
            in: 'Atestado',
            out: 'Atestado'
          },
          {
            in: 'Atestado',
            out: 'Atestado'
          }
        ]
        //setUserTime(newUserTime)
        setUserTime(newUserTime)
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <FormGroup style={{ justifyContent: "space-between" }}>
        <Title>Outros registros</Title>
        <CloseButton onClick={() => handleModal()}> X </CloseButton>
      </FormGroup>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
          <Col>
            <Label>Data</Label>
            <Input
              type='date'
              value={moment.utc(userTime.date).format("YYYY-MM-DD")}
              onChange={e => updateDataChanged(e)}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <FormGroup style={{ padding: 10, alignSelf: 'center' }}>
            <Input
              id="Falta"
              style={{ height: 16, width: 16, marginRight: 5 }}
              type='checkbox'
              onChange={e => handleInputCheck(e)}
            />
            <Label style={{ fontWeight: 500, marginRight: 20 }}>
              Falta
            </Label>

            <Input
              id="Aviso Prévio"
              style={{ height: 16, width: 16, marginRight: 5 }}
              type='checkbox'
              onChange={e => handleInputCheck(e)}
            />
            <Label style={{ fontWeight: 500, marginRight: 20 }}>
              Aviso Prévio
            </Label>

            <Input
              id="Feriado"
              style={{ height: 16, width: 16, marginRight: 10 }}
              type='checkbox'
              onChange={e => handleInputCheck(e)}
            />
            <Label style={{ fontWeight: 500, marginRight: 20 }}>
              Feriado
            </Label>

            <Input
              id="Atestado"
              style={{ height: 16, width: 16, marginRight: 10 }}
              type='checkbox'
              onChange={e => handleInputCheck(e)}
            />
            <Label style={{ fontWeight: 500, marginRight: 20 }}>
              Atestado
            </Label>

            <Input
              id="Folga"
              style={{ height: 16, width: 16, marginRight: 5 }}
              type='checkbox'
              onChange={e => handleInputCheck(e)}
            />
            <Label style={{ fontWeight: 500 }}>
              Folga
            </Label>
          </FormGroup>
          <Button
            onClick={() => handleNewOtherTimes(userTime)}
            bgColor={"#87B87F"}
          > Salvar registro
          </Button>
        </FormGroup>
      </Form>

    </Container>
  )
};

export default TimeModal;
/**
  const onSave = async () => {
    if (fullName === '' || cpf === '' || email === '' || phone === '' || password === '') {
      addToast('Preencha tdos os campos!', { appearance: 'error', autoDismiss: true })
    } else {
      await postUser(fullName, cpf, email, phone, company, role, password,
        onSuccess => (addToast(onSuccess.message, { appearance: 'success', autoDismiss: true }), listUsers()),
        onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
      )
    }
  }


  const updateTimesChanged = index => e => {
    let newUserTime = { ...userTime }; // copying the old datas array

    newUserTime.times[index][e.target.name] = e.target.value; // replace e.target.value with whatever you want to change it to
    if (e.target.value === '') {
      delete newUserTime.times[index][e.target.name]
      if (Object.entries(newUserTime.times[index]).length === 0) {
        newUserTime.times.splice(index, 1);
      }
    }
    setUserTime(newUserTime); // ??
  }
  const addHour = () => {
    if (userTime.times.length < 2) {
      let newUserTime = { ...userTime }; // copying the old datas array

      newUserTime.times.push({
        in: '00:00',
        out: '00:00'
      })
      setUserTime(newUserTime); // ??
    }
  }
  const handleUpdateTime = async (time) => {
    await putUserTime(
      time,
      // eslint-disable-next-line no-sequences
      onSuccess => (listUserTimes(), addToast(onSuccess.message, { appearance: 'success', autoDismiss: true })),
      onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
    )
  }



  const onUpdate = async () => {
    let data = !password
      ? { _id, fullName, cpf, email, phone, company, role }
      : { _id, fullName, cpf, email, phone, company, role, password };

    await putUser(data,
        onSuccess => (addToast(onSuccess.message, { appearance: 'success', autoDismiss: true }), listUsers(), handleModal()),
        onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
      )
  }

  const clearUserData = () => {
    setFullName('')
    setCpf('')
    setEmail('')
    setPhone('')
    setCompany('Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0001-45')
    setRole('PUBLIC')
    setPassword('')
  } */