import styled from 'styled-components'

export const Row = styled.div`
display: flex;
flex-direction: row;
`
export const Col = styled.div`
display: flex;
flex-direction: column;
`
export const Title = styled.h1`
margin-left: 10px;
font-weight: 500;
font-size: 16pt;
color: white;
`
export const Button = styled.button`
width: 140px;
color: ${props => props.color ?? "white"};
margin: 0 0 0 10px;
border:none;
cursor: pointer;
height: 38px;
background: ${props => props.bgColor};
border-radius: 4px;
font-weight: 500;
font-size: 15px;
:hover{
  background: #2D323E;
  color: white;
}
`
export const UserButton = styled.button`
width: 50px;
color: ${props => props.color ?? "white"};
margin: 2px 2px;
border:none;
cursor: pointer;
height: 28px;
background: ${props => props.bgColor};
border-radius: 4px;
font-weight: 500;
font-size: 10px;
:hover{
  background: #2D323E;
  color: white;
}
`
export const Table = styled.table`
color: #919098;
width: 100%;
border:none;
border-spacing: 0;
`
export const TableRow = styled.tr`
cursor: pointer;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 8px;
&:hover{
  background-color: rgba(124, 155, 2011, 0.08);
}
&:active{
  background-color: rgba(124, 155, 2011, 0.2);
}
td {
  padding: 10px;
  &:last-child{
  text-align: right;
}
}
`
export const TableHead = styled.thead`
color: black;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 12px;
text-align: left;
flex: 1;
th {
  padding: 10px;
  &:last-child{
  text-align: right;
  }
}
`
export const Pagination = styled.ul`
  display: flex;
  list-style: none;
  img{
  width: 20px;
  height: 20px;
  }
  li{
  cursor: pointer;
  padding: 2px;
  margin-left: -1px;
  width: 28px;
  height: 25px;
  border: 1px solid rgb(216, 216, 216);
	text-align: center;
  font-size: 1em;
  background-color: white;
  &:last-child{
  border-radius: 0px 5px 5px 0px ;
  }
  &:first-child{
  border-radius: 5px 0px 0px 5px ;
  }
  &:hover{
  background-color: rgba(215, 93, 233, .2);
}
  &:active{
  background-color: rgba(215, 93, 233, .5);
  color: #fafafa;
}
}
`
export const FormGroup = styled.div`
display: flex;
flex-direction: row;
`

