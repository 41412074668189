/* eslint-disable react-hooks/exhaustive-deps */
import { useToasts } from 'react-toast-notifications';
import React, { useState, useContext, useEffect } from "react";
import { Container, Title, Input, Form, FormGroup, Label, Col, Button, CloseButton } from './styles.js'
import { ModalContext } from "../../../contexts/modalContext";
import { createTimeRequest } from '../../../services/apiSaoLuis/timeRequest'
import moment from 'moment'
import { COLORS } from '../../styles.js';

const TimeRequestModal = ({ listUserTimes, time, id }) => {
  const { handleModal } = useContext(ModalContext);
  const { addToast } = useToasts();
  const [userTime, setUserTime] = useState({
    date: moment.utc().format("YYYY-MM-DD"),
    times: [],
    userId: id
  });
  const [motivation, setMotivation] = useState('');

  useEffect(() => {
    setUserTime(time)
  }, [])

  const updateDataChanged = e => {
    let newUserTime = { ...userTime };
    newUserTime.date = e.target.value
    setUserTime(newUserTime);
  }

  const updateTimesChanged = index => e => {
    let newUserTime = { ...userTime }; // copying the old datas array

    newUserTime.times[index][e.target.name] = e.target.value; // replace e.target.value with 
    setUserTime(newUserTime);
  }

  const handleRequestPutTime = async () => {
    if (motivation === '') {
      addToast('Preencha todos os campos!',
        { appearance: 'error', autoDismiss: true })
    } else {
      await createTimeRequest(
        userTime,
        'retificação',
        'pendente',
        motivation,
        // eslint-disable-next-line no-sequences
        onSuccess => {
          listUserTimes();
          addToast(
            onSuccess.message,
            { appearance: 'success', autoDismiss: true }
          );
          handleModal()
        },
        onFail => {
          addToast(onFail.message,
            { appearance: 'error', autoDismiss: true })
        }
      )
    }
  };

  return (
    <Container>
      <FormGroup style={{ justifyContent: "space-between" }}>
        <Title>Solicitar retificação de ponto</Title>
        <CloseButton onClick={() => handleModal()}> X </CloseButton>
      </FormGroup>
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormGroup>
          <Col>
            <Label>Data</Label>
            <Input
              type='date'
              value={moment.utc(userTime.date).format("YYYY-MM-DD")}
              onChange={e => updateDataChanged(e)}
              disabled={true}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          {userTime.times[0]?.in
            ? <Col style={{ marginRight: 10 }}>
              <Label>Entrada 1</Label>
              <Input
                type='time'
                name='in'
                value={userTime.times[0]?.in || ''}
                onChange={updateTimesChanged(0)}
              />
            </Col>
            : null
          }
          {userTime.times[1]?.out
            ? <Col>
              <Label>Saida 1</Label>
              <Input
                type='time'
                value={userTime.times[1]?.out || ''}
                name='out'
                onChange={updateTimesChanged(1)}
              />
            </Col>
            : null
          }
        </FormGroup>
        <FormGroup>
          {userTime.times[2]?.in
            ? <Col style={{ marginRight: 10 }}>
              <Label>Entrada 1</Label>
              <Input
                type='time'
                name='in'
                value={userTime.times[2]?.in || ''}
                onChange={updateTimesChanged(2)}
              />
            </Col>
            : null
          }
          {userTime.times[3]?.out
            ? <Col>
              <Label>Saida 1</Label>
              <Input
                type='time'
                value={userTime.times[3]?.out || ''}
                name='out'
                onChange={updateTimesChanged(3)}
              />
            </Col>
            : null
          }
        </FormGroup>
        <FormGroup>
          <Col>
            <Label>Motivo</Label>
            <Input
              type='text'
              value={motivation}
              onChange={(e) => setMotivation(e.target.value)}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Button
            onDoubleClick={() => handleRequestPutTime()}
            bgColor={COLORS.GREEN}>
            Solicitar Retificação
            </Button>
        </FormGroup>
      </Form>

    </Container>
  )
};

export default TimeRequestModal;
/**
 *
 *  <Button
            onClick={() => handleRequestDeleteTime()}
            bgColor={COLORS.ORANGE}>
            Solicitar Exclusão
            </Button>


 * <Button
            onClick={() => addHour()}
            bgColor={COLORS.LIGHTBLUE}>
            Adicionar 2º Entrada e saida
            </Button>

            const addHour = () => {
    if (userTime.times.length < 2) {
      let newUserTime = { ...userTime }; // copying the old datas array

      newUserTime.times.push({
        in: '00:00',
        out: '00:00'
      })
      setUserTime(newUserTime); // ??
    }
  }
 *
 *
  const onSave = async () => {
    if (fullName === '' || cpf === '' || email === '' || phone === '' || password === '') {
      addToast('Preencha tdos os campos!', { appearance: 'error', autoDismiss: true })
    } else {
      await postUser(fullName, cpf, email, phone, company, role, password,
        onSuccess => (addToast(onSuccess.message, { appearance: 'success', autoDismiss: true }), listUsers()),
        onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
      )
    }
  }

  const onUpdate = async () => {
    let data = !password
      ? { _id, fullName, cpf, email, phone, company, role }
      : { _id, fullName, cpf, email, phone, company, role, password };

    await putUser(data,
        onSuccess => (addToast(onSuccess.message, { appearance: 'success', autoDismiss: true }), listUsers(), handleModal()),
        onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
      )
  }

  const clearUserData = () => {
    setFullName('')
    setCpf('')
    setEmail('')
    setPhone('')
    setCompany('Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0001-45')
    setRole('PUBLIC')
    setPassword('')
  } */