import React, { createContext } from "react";
import useUser from "../hooks/useUser";

let UserContext;
let { Provider } = (UserContext = createContext());

let UserProvider = ({ children }) => {
  let { user, loading, getUser, setuserNull, signIn } = useUser();

  return (
    <Provider value={{ user, loading, getUser, setuserNull, signIn }} >
      {children}
    </Provider>
  );
};

export { UserContext, UserProvider };


