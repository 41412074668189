import axios from 'axios';
import { BASE_URL } from '../../config/config.json'

export const getTimesToday = async () => {
  try {
    var response = await axios({
      url: `${BASE_URL}/userTimes/today`,
      method: 'get',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
};

export const getTimesDayOff = async () => {
  try {
    var response = await axios({
      url: `${BASE_URL}/userTimes/dayOff`,
      method: 'get',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
}
//postUsertime from allUsers
export const postUserTimes = async (userTime, onSuccess) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/userTimes`,
      method: 'post',
      timeout: 8000,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: userTime
    })
    return onSuccess(response.data)
  } catch (error) {
    return (error.response.data)
  }
}

export const postNewUserTime = async (time, onSuccess,) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/userTimes/${time.userId}`,
      method: 'post',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: time
    })
    return onSuccess(response.data)
  } catch (error) {
    return console.log(error)
  }
}

export const putUserTime = async (time, onSuccess) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/userTimes/${time._id}`,
      method: 'put',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: { date: time.date, times: time.times }
    })
    return onSuccess(response.data)
  } catch (error) {
    return console.log(error)
  }
}

export const deleteUserTime = async (_id, onSuccess, onFail) => {
  try {
    var response = await axios.delete(`${BASE_URL}/userTimes/${_id}`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    return onSuccess(response.data)
  } catch (error) {
    return onFail(error.response.data)
  }
}

export const getAllUserTimes = async () => {
  try {
    var response = await axios.get(`${BASE_URL}/userTimes`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
}

export const generateAfd = async (company, users, dateI, dateF, onSuccess, onFail) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/userTimes/afd/generate`,
      method: 'post',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: { company, users, dateI, dateF }
    })
    return onSuccess(response.data.message)
  } catch (error) {
    return onFail(error)
  }
}
export const downloadAfd = async (company, onSuccess, onFail) => {
  let companyCnpj = company.split(' - ')[2].replace(/[^0-9]/g, '')
  try {
    var response = await axios({
      url: `${BASE_URL}/userTimes/afd/download/${companyCnpj}`,
      method: 'get',
      responseType: 'blob', // important
      timeout: 8000,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Afd${companyCnpj}.txt`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    return onFail('Ocorreu um Erro ou Arquivo nao Gerado!')
  }
}