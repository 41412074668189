import styled from 'styled-components'
import { COLORS } from '../../components/styles'

export const Container = styled.div`
background-color: ${COLORS.BACKGROUND};
height: 100vh;
align-items: center;
justify-content: center;
flex-direction: column;
`
export const Content = styled.div`
position: absolute;
top: 55px;
left: 13vw;
width: 87vw;
display: flex;
flex: 1;
padding: 9px 20px;
min-height: 87vh;
flex-direction: column;
  @media(max-width: 1024px) {  
  padding: 20px 2%;
}
  @media(max-width: 425px) {
  left: 0px;
  width: 100vw;
  padding: 20px 0;
}
`
export const Row = styled.div`
display: flex;
flex-direction: row;
  @media(max-width: 768px) {
    flex-direction: column;  
  }
`
export const Col = styled.div`
display: flex;
flex-direction: column;
`
export const Title = styled.h1`
margin-left: 10px;
font-weight: 500;
font-size: 16pt;
color: white;
`
export const Button = styled.button`
width: 140px;
color: ${props => props.color ?? "white"};
margin: 5px;
border:none;
cursor: pointer;
height: 38px;
background: ${props => props.bgColor};
border-radius: 4px;
font-weight: 500;
font-size: 15px;
:hover{
  background: #2D323E;
  color: white;
}
`
export const Table = styled.table`
color: #919098;
padding: 5px 0;
border:none;
border-spacing: 0;

`
export const TableRow = styled.tr`
padding: 10px;
cursor: pointer;
font-size: 14px;
line-height: 25px;
font-weight: 400;
text-align: left;
border-top: 1px solid #DCDCDC;
&:hover{
  background-color: rgba(124, 155, 2011, 0.08);
}
&:active{
  background-color: rgba(124, 155, 2011, 0.2);
}
:nth-child(even) {
  background-color: #f2f2f2;
}
td {
  padding: 10px;
}
`
export const TableHead = styled.thead`

padding: 10px;
color: black;
font-size: 18px;
text-align: left;
flex: 1;
th {
  padding: 10px;
}
`
export const Pagination = styled.ul`
  display: flex;
  list-style: none;
  img{
  width: 20px;
  height: 20px;
  }
  li{
  cursor: pointer;
  padding: 2px;
  margin-left: -1px;
  width: 28px;
  height: 25px;
  border: 1px solid rgb(216, 216, 216);
	text-align: center;
  font-size: .5em;
  background-color: white;
  &:last-child{
  border-radius: 0px 5px 5px 0px ;
  }
  &:first-child{
  border-radius: 5px 0px 0px 5px ;
  }
  &:hover{
  background-color: rgba(215, 93, 233, .2);
}
  &:active{
  background-color: rgba(215, 93, 233, .5);
  color: #fafafa;
}
}
`
export const FormGroup = styled.div`
display: flex;
flex-direction: row;
`

