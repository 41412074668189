import styled from 'styled-components';
import { COLORS } from '../../components/styles';

export const Container = styled.div`
align-items: center;
justify-content: center;
flex-direction: column;
`
export const TitleBar = styled.div`
position: fixed;
display: flex;
flex: 1;
align-items: center;
justify-content: space-between;
width: 87vw;
height: 5vh;
right: 0px;
top: 0px;
background: #1C3E7E;
padding: 0 25px 0 5px;
`
export const Content = styled.div`
z-index: 10;
position: absolute;
top: 5vh;
left: 13vw;
width: 87vw;
padding: 0px 1%;
background-color: #FFFFFF;
display: flex;
flex: 1;
min-height: 87vh;
flex-direction: column;
`
export const Row = styled.div`
display: flex;
flex-direction: row;
`
export const Col = styled.div`
display: flex;
flex-direction: column;
`
export const Title = styled.h1`
margin-left: 10px;
font-weight: 500;
font-size: 16pt;
color: white;
`
export const Button = styled.button`
width: 140px;
color: ${props => props.color ?? "white"};
margin: 5px;
border:none;
cursor: pointer;
height: 38px;
background: ${props => props.bgColor ?? COLORS.GREEN};
border-radius: 4px;
font-weight: 500;
font-size: 15px;
:hover{
  background: ${COLORS.BLUE};
}
`
export const UserButton = styled.button`
width: 50px;
color: ${props => props.color ?? "white"};
margin: 2px 2px;
border:none;
cursor: pointer;
height: 32px;
background: ${props => props.bgColor};
border-radius: 4px;
font-weight: 500;
font-size: 12px;
:hover{
  background: #2D323E;
  color: white;
}
`
export const Table = styled.table`
color: #919098;
border:none;
border-spacing: 0;
`
export const TableRow = styled.tr`
cursor: pointer;
font-size: .4em;
line-height: 15px;
font-weight: 400;
text-align: left;
&:hover{
  background-color: rgba(124, 155, 2011, 0.08);
}
&:active{
  background-color: rgba(124, 155, 2011, 0.2);
}
td {
  padding-left: 5px;
  border-top: 1px solid #DCDCDC;
  &:last-child{
  text-align: right;
}
}
`
export const TableHead = styled.thead`
color: black;
font-size: .5em;
text-align: left;
flex: 1;
th {
  padding-left: 5px;
}
`
export const Pagination = styled.ul`
  display: flex;
  list-style: none;
  img{
  width: 20px;
  height: 20px;
  }
  li{
  cursor: pointer;
  padding: 2px;
  margin-left: -1px;
  width: 28px;
  height: 25px;
  border: 1px solid rgb(216, 216, 216);
	text-align: center;
  font-size: 1em;
  background-color: white;
  &:last-child{
  border-radius: 0px 5px 5px 0px ;
  }
  &:first-child{
  border-radius: 5px 0px 0px 5px ;
  }
  &:hover{
  background-color: rgba(215, 93, 233, .2);
}
  &:active{
  background-color: rgba(215, 93, 233, .5);
  color: #fafafa;
}
}
`
export const FormGroup = styled.div`
display: flex;
margin: 10px 0;
flex-direction: row;
`
export const Form = styled.form`
display: flex;
flex: 1;
flex-direction: column;
padding: 10px;
`
export const Label = styled.label`
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 14px;
`
export const Select = styled.select`
display: flex;
color: black;
font-weight: 400;
font-size: 11pt;
border: 0;
padding: 9px 8px;
border-radius: 4px;
background: ${COLORS.BACKGROUND};
&:focus {
  background: ${COLORS.GREY};
  outline: 0;
}
`
export const Input = styled.input`
display: flex;
color: black;
font-weight: 400;
font-size: 11pt;
border: 0;
padding: 10px 8px;
border-radius: 4px;
background: ${COLORS.BACKGROUND};
&:focus {
  background: ${COLORS.GREY};
  outline: 0;
}
`