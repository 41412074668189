import React, { useContext, useEffect, useState } from 'react'
import { Row, Button, Input, Table, Text, TableRow, Col, TableHead } from './styles'
import { ReactComponent as ArrowBackIcon } from '../../assets/arrowBackIcon.svg'
import { Container, Content, Card } from '../../components/styles'
import BarLeft from '../../components/BarLeft'
import BarTop from '../../components/BarTop'
import { useHistory, useLocation } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
import { ModalContext } from '../../contexts/modalContext'
import TimeModal from '../../components/Modal/TimeModal'
import VacationModal from '../../components/Modal/VacationModal'
import { getUserTime } from '../../services/apiSaoLuis/user'
import moment from 'moment'
import { UserContext } from '../../contexts/userContext'
import Spinner from '../../components/Spinner'
import GoogleMapReact from 'google-map-react';
import Marker from '../../components/Marker'

const Index = () => {
  let history = useHistory();
  let location = useLocation();
  const { addToast } = useToasts();
  const { handleModal } = useContext(ModalContext);
  const { getUser, user } = useContext(UserContext);
  const { from } = location.state || { from: { pathname: "/" } };
  const [userTimes, setUserTimes] = useState([])
  const [userVacations, setUserVacations] = useState([])
  const [sumTimes, setSumTimes] = useState('')
  const [date, setDate] = useState(moment().format("yyyy-MM"))
  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    !user && getUser()
    if (user !== null) {
      if (user && user.role === 'ADMIN') {
        listUserTimes()
        listUserVacations()
      } else {
        addToast('Você não tem permissão para acessar essa área!', { appearance: 'error', autoDismiss: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  useEffect(() => {
    let duracaoTotal = moment.duration(0);
    userTimes?.map((item) => {
      return duracaoTotal.add(moment.duration(item.totalTimes))
    })
    setSumTimes(
      Math.floor(duracaoTotal.asHours()) + " Horas e " +
      duracaoTotal.minutes() + " minutos")
  }, [userTimes])

  useEffect(() => {
    let arr = []
    userTimes.map((item) => {
      return item.times.map((subItem) => {
        return arr.push({ hour: subItem?.in || subItem?.out, date: item.date, lat: subItem?.location.latitude, lgn: subItem?.location.longitude })
      })
    })
    setLocations(arr)
  }, [userTimes])

  const listUserTimes = async () => {
    setLoading(true)
    let dateSplit = date.split('-')
    let userTimes = await getUserTime(
      parseInt(dateSplit[0]),
      parseInt(dateSplit[1]),
      location.state.user._id
    )
    setUserTimes(userTimes.data)
    setLoading(false)
  };

  const listUserVacations = () => {
    setUserVacations(location.state.user.vacations)
  };

  const RenderTableData = () => {
    if (userTimes === undefined)
      return null
    else {
      return userTimes?.map((time, index) => {
        return (
          <TableRow key={index}>
            <td style={{ flex: 2, alignSelf: 'stretch', fontSize: 11 }}>
              {moment.utc(time.date).format("ddd, ")}
              {moment.utc(time.date).format("DD [de] MMM, yyyy")}
            </td>
            <td style={{ flex: 1, fontSize: 11 }}>{time.times[0]?.in}</td>
            <td style={{ flex: 1.2, fontSize: 11 }}>{time.times[1]?.out}</td>
            <td style={{ flex: 1.2, fontSize: 11 }}>{time.times[2]?.in}</td>
            <td style={{ flex: 0.6, fontSize: 11 }}>{time.times[3]?.out}</td>
            <td style={{ flex: 0.6, fontSize: 11 }}>{time.totalTimes}</td>
          </TableRow>
        )
      })
    }
  }

  const RenderTableVacations = () => {
    return userVacations.map((date, index) => {
      return (
        <Row key={index}>
          <h5 style={{ fontSize: '.5em' }}> {moment.utc(date.start).format("DD/MM/YYYY")} à {moment.utc(date.end).format("DD/MM/YYYY")} </h5>
        </Row>
      )
    })
  };

  return (
    <Container>
      <BarLeft menuSelected='Ponto Eletrônico' subMenuSelected='Banco de Horas' />
      <BarTop title='Banco de horas' />
      {user && user.role !== 'ADMIN'
        ? null
        : <Content>
          {loading
            ? <Spinner />
            :
            <Card>
              <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Col>
                  <Text>{location.state.user.fullName}</Text>
                </Col>
                <Col>
                  <Row>
                    <Input
                      type='month'
                      defaultValue={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                    <Button
                      onClick={() => history.replace({
                        pathname: "/pdf",
                        state: {
                          user: location.state.user,
                          times: userTimes,
                          date: date,
                          sumTimes: sumTimes,
                          from: { pathname: "/times/bank" }
                        }
                      })}
                      bgColor={'#EE9600'}>
                      Gerar PDF
                </Button>
                    <Button
                      onClick={() => handleModal(<TimeModal listUserTimes={listUserTimes} type={'new'} id={location.state.user._id} />)}
                      bgColor={'#87B87F'}>
                      Outros registros
                </Button>
                    <Button
                      onClick={() => handleModal(<VacationModal
                        listUserTimes={
                          (userVacation) => setUserVacations(userVacation)
                        }
                        type={'new'}
                        dates={userVacations}
                        userId={location.state.user._id} />)}
                      bgColor={'#1C3E7E'}>
                      Registrar Férias
                </Button>
                    <ArrowBackIcon style={{ margin: 12, cursor: 'pointer' }}
                      onClick={() => history.replace(from)}
                    />
                  </Row>
                </Col>
              </Row>
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col style={{ lineHeight: 2 }}>
                  {userVacations ? <div><h5 style={{ fontSize: '.6em' }}>Férias:</h5><RenderTableVacations /></div> : null}
                </Col>
                <Col style={{ lineHeight: 2 }}>
                  <p style={{ fontSize: '.4em' }}>Total de horas trabalhadas no mês: {sumTimes}</p>
                </Col>
              </Row>
              <Table>
                <TableHead>
                  <tr>
                    <th>Data</th>
                    <th>Entrada 1</th>
                    <th>Saída 1</th>
                    <th>Entrada 2</th>
                    <th>Saída 2</th>
                    <th>Total de Horas</th>
                  </tr>
                </TableHead>
                <tbody>
                  <RenderTableData />
                </tbody>
              </Table >
              <Row style={{ height: '50vh', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyBq2ZB0YYlPrEjoaP0Y9c1iHpTlRcd03Rk' }}
                  defaultCenter={{
                    lat: -7.4059472,
                    lng: -35.1123886
                  }}
                  defaultZoom={10}
                  hoverDistance={20}
                >

                  {locations.map((item, index) => {
                    return <Marker
                      key={index}
                      hour={item.hour}
                      date={item.date}
                      lat={item.lat}
                      lng={item.lgn}
                    />
                  })}
                </GoogleMapReact>
              </Row>
            </Card>
          }
      </Content>
      }
    </Container >

  )

}

export default Index;


/****
 *
 *
 *
 *  const handleDeleteTime = async (time) => {
    var r = window.confirm(`Tem certeza que deseja remover o ponto do dia ${moment.utc(time.date).format("D, MMMM, YYYY")}`);
    if (r === true) {
      await deleteUserTime(
        time._id,
        // eslint-disable-next-line no-sequences
        onSuccess => {
          listUserTimes();
          addToast(onSuccess.message,
            {
              appearance: 'success',
              autoDismiss: true
            })
        },
        onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
      )
    } else {
      return null
    }
  };
 */