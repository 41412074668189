import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment'

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: red;
  border: 2px solid #fff;
  border-radius: 100%; 
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const Label = styled.div`
  position: absolute;
  display: none;
  top: -55px;
  left: -55px;
  width: 120px;
  height: 45px;
  padding: 5px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid lightgrey;
  font-size: 11pt;
  font-weight: bold;
  text-align: center;
  ${Wrapper}:hover & {
    display: block;
  }
`;

const Marker = ({ hour, date }) => (
  <div>

    <Wrapper
      alt={'text'}
    >
      <Label>
        {moment.utc(date).format("DD [de] MMMM, yyyy")
          + ` às ${hour}`}
      </Label>
    </Wrapper>
  </div>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;