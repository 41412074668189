import styled from 'styled-components'

export const Container = styled.div`
align-items: center;
justify-content: center;
flex-direction: column;
`
export const Content = styled.div`
z-index: 10;
position: absolute;
top: 5vh;
left: 13vw;
width: 87vw;
padding: 0px 1%;
background-color: #FFFFFF;
display: flex;
flex: 1;
min-height: 87vh;
flex-direction: column;
  @media(max-width: 1024px) {  
  padding: 20px 2%;
}
  @media(max-width: 425px) {
  left: 0px;
  width: 100vw;
  padding: 20px 0;
}
`
export const Row = styled.div`
display: flex;
align-items: center;
flex-direction: row;

`
export const Col = styled.div`
display: flex;
flex-direction: column;
`
export const Button = styled.button`
width: 140px;
color: ${props => props.color ?? "white"};
margin: 0 0 0 10px;
border:none;
cursor: pointer;
height: 38px;
background: ${props => props.bgColor};
border-radius: 4px;
font-weight: 500;
font-size: 15px;
:hover{
  background: #2D323E;
  color: white;
}
`
export const Table = styled.table`
width: 100%;
color: #919098;
border:none;
border-spacing: 0;
`
export const TableRow = styled.tr`
cursor: pointer;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 8px;
&:hover{
  background-color: rgba(124, 155, 2011, 0.08);
}
&:active{
  background-color: rgba(124, 155, 2011, 0.2);
}
td {
  padding: 10px;
  &:last-child{
  text-align: right;
}
}
`
export const TableHead = styled.thead`
color: black;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 12px;
text-align: left;
flex: 1;
th {
  padding: 10px;
  &:last-child{
  text-align: right;
  }
}
`
export const Pagination = styled.ul`
  display: flex;
  list-style: none;
  img{
  width: 20px;
  height: 20px;
  }
  li{
  cursor: pointer;
  padding: 2px;
  margin-left: -1px;
  width: 28px;
  height: 25px;
  border: 1px solid rgb(216, 216, 216);
	text-align: center;
  font-size: 1em;
  background-color: white;
  &:last-child{
  border-radius: 0px 5px 5px 0px ;
  }
  &:first-child{
  border-radius: 5px 0px 0px 5px ;
  }
  &:hover{
  background-color: rgba(215, 93, 233, .2);
}
  &:active{
  background-color: rgba(215, 93, 233, .5);
  color: #fafafa;
}
}
`
export const FormGroup = styled.div`
display: flex;
flex-direction: row;
`
export const Input = styled.input`
display: flex;
height: 38px;
width: 200px;
padding: 5px;
font-size: 11pt;
background: #F5F5F5;
border: 0;
border-radius: 4px;
`
export const Text = styled.p`
font-style: normal;
font-weight: bold;
font-size: 16px;
`
