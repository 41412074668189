import styled from 'styled-components'

export const Container = styled.div`
font-weight: 500;
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
width: 100vw;
`
export const Row = styled.div`
justify-content: space-between;
display: flex;
flex-direction: row;
`
export const ContainerForm = styled.form`
align-items: center;
display: flex;
flex-direction: column;
`
export const Img = styled.img`
height: 200px;
margin-bottom: 30px;
`
export const ContainerButtons = styled.div`
display: flex;
flex-direction: row;
`
export const Button = styled.button`
margin: 10px;
flex-direction: row;
cursor: pointer;
padding: 10px;
text-align: center;
font-weight: 500;
font-size: 11pt;
border: none;
width: 123px;
height: 38px;
color: white;
background: ${props => props.inputColor};
border-radius: 4px;
&:hover {
  background: #A0A1B6;
}
`
export const Message = styled.p`
font-size: 11pt;
font-weight: 400;
color: white;
`