import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;  
  background: rgba(200,200,200,0.7);
  z-index: 20;
  -webkit-transition: all 0.5s 0.5s ease-in-out;
  transition: all 0.5s 0.5s ease-in-out;
`