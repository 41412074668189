import { useToasts } from 'react-toast-notifications';
import React, { useState, useContext, useEffect } from "react";
import { Container, Title, Input, Form, FormGroup, Label, Col, Button, CloseButton } from './styles.js'
import { ModalContext } from "../../../contexts/modalContext";
import { putUserVacation } from '../../../services/apiSaoLuis/user'
import moment from 'moment'

const VacationModal = ({ listUserTimes, dates, userId }) => {
  let { handleModal } = useContext(ModalContext);
  const { addToast } = useToasts();
  const [userVacation, setUserVacation] = useState([]);

  useEffect(() => {

    if (dates !== undefined) {
      setUserVacation(dates)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const updateDataChanged = index => e => {
    let newUserCavation = userVacation;

    newUserCavation[index][e.target.name] = new Date(e.target.value);
    setUserVacation(newUserCavation);
  }

  const handleNewTime = (e) => {
    e.preventDefault();
    setUserVacation([...userVacation, { start: '', end: '' }]);

  }

  const handleDeleteVacation = index => (e) => {
    const items = [...userVacation];
    items.splice(index, 1);
    setUserVacation(items)

  }

  const handleUpdateTime = async (userVacation) => {
    await putUserVacation(
      userVacation,
      userId,
      // eslint-disable-next-line no-sequences
      onSuccess => (listUserTimes(userVacation), addToast(onSuccess.message, { appearance: 'success', autoDismiss: true })),
      onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
    )
  };

  return (
    <Container>
      <FormGroup style={{ justifyContent: "space-between" }}>

        <Title>Registrar Férias</Title>

        <CloseButton onClick={() => handleModal()}> X </CloseButton>
      </FormGroup>
      <Form onSubmit={(e) => e.preventDefault()}>
        {userVacation.map((dates, index) => {
          return (
            <div key={index}>
              <FormGroup >
                <Label>Inicio</Label>
                <Col>
                  <Input
                    key={index}
                    type='date'
                    name='start'
                    defaultValue={moment.utc(dates.start).format("YYYY-MM-DD")}
                    onChange={updateDataChanged(index)}
                  />
                </Col>
                <Label>Final</Label>
                <Col>
                  <Input
                    key={index}
                    type='date'
                    name='end'
                    defaultValue={moment.utc(dates.end).format("YYYY-MM-DD")}
                    onChange={updateDataChanged(index)}
                  />
                </Col>
                <Button
                  onClick={handleDeleteVacation(index)}
                  bgColor={"#EE9600"}>
                  Excluir
                  </Button>
              </FormGroup>
            </div>
          )
        })}

        <FormGroup>
          <div>
            <Button
              onClick={(e) => handleNewTime(e)}
              bgColor={"#EE9600"}>
              Adicionar Novas Férias
            </Button>
            <Button
              onClick={() => handleUpdateTime(userVacation)}
              bgColor={"#87B87F"}>
              Salvar Férias
              </Button>
          </div>

        </FormGroup>
      </Form>

    </Container>
  )

};

export default VacationModal;


/**  const addHour = () => {

    let newUserVacation = { ...userVacation }; // copying the old datas array

    newUserVacation.push({
      start: moment.utc().format("YYYY-MM-DD"),
      end: moment.utc().format("YYYY-MM-DD"),
    })
    setUserVacation(newUserVacation); // ??
  }
} */