import styled from 'styled-components'
import { COLORS } from '../styles'

export const Img = styled.img`
height: 45px;
width: 11vw;
margin: 30px 0;
`

export const Container = styled.div`
align-items: center;
z-index: 100;
position: fixed;
width: 13vw;
height: 100vh;
left: 0px;
top: 0vh;
background: ${COLORS.BLUE};
@media(max-width: 425px) {
  opacity: 0;
  }
`

export const Button = styled.div`
cursor: pointer;
color: white;
font-weight: 400;
line-height: 30pt;
font-size: .45em;
display: flex;
padding: 0 0 0 6px;
background: ${(props) => props.active ? "#039BE5" : "#2D323E"};
color: "white";
@media(min-width: 2560px) {
font-size: .4em;
  }
&:hover{
  color: white;
  background: #464951;
}
`

export const User = styled.div`
font-size: .4em;
display: flex;
margin-bottom: 5px;
flex: 1;
justify-content: flex-end; 
color: white;
@media(min-width: 2560px) {
font-size: .35em;
  }
`

export const ExitBtn = styled.img`
display: flex;
margin: 8px;
cursor: pointer;
height: 2vh;
opacity: .8;
transition: all 0.2s ease-in;
&:hover {
  opacity: 1;
}
`