import { useToasts } from 'react-toast-notifications';
import React, { useContext, useEffect, useState } from 'react'
import { Row, Table, TableRow, TableHead } from './styles'
import { Container, Content, Card } from '../../components/styles'
import BarLeft from '../../components/BarLeft'
import BarTop from '../../components/BarTop'
import { UserContext } from '../../contexts/userContext';
import { getAllUsers } from '../../services/apiSaoLuis/user'
import InputSearch from '../../components/InputSearch';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';

const Index = () => {
  const { getUser, user } = useContext(UserContext);
  const { addToast } = useToasts();
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    !user && getUser()
    if (user !== null) {
      if (user && user.role === 'ADMIN') {
        listUsers()
      } else {
        addToast('Você não tem permissão para acessar essa área!', { appearance: 'error', autoDismiss: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const listUsers = async () => {
    setLoading(true)
    let users = await getAllUsers()
    setUsers(users.data)
    setFilteredData(users.data)
    setLoading(false)
  }

  const RenderTableData = () => {
    return filteredUsers && filteredUsers.map((user, index) => {
      return (
        <TableRow key={index}>
          <td><Link style={{ color: '#919098' }} to={{
            pathname: "/times/manage",
            state: { user: user, from: { pathname: "/times/bank" } }
          }}>{user.fullName}</Link></td>
          <td><Link style={{ color: '#919098' }} to={{
            pathname: "/times/manage",
            state: { user: user, from: { pathname: "/times/bank" } }
          }}>{user.cpf}</Link></td>
        </TableRow>

      )
    })
  }

  const FilterTableData = (name) => {
    const lowercasedValue = name.toLowerCase().trim();
    if (lowercasedValue === "") setFilteredData(users);
    else {
      const filteredData = users.filter(item => {
        return Object.keys(item).some(key =>
          item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setFilteredData(filteredData);
    }
  }

  return (
    <Container>
      <BarLeft menuSelected='Ponto Eletrônico' subMenuSelected='Banco de Horas' />
      <BarTop title='Banco de horas' />
      {user && user.role !== 'ADMIN'
        ? null
        :
        <Content>{loading
          ? <Spinner />
          : <Card ><Row style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <InputSearch
              placeholder='Insira um nome para pesquisar'
              type='text'
              onChange={(e) => FilterTableData(e.target.value)}
            />
          </Row>
            <Table>
              <TableHead>
                <tr>
                  <th>Nome do colaborador</th>
                  <th>CPF</th>
                </tr>
              </TableHead>
              <tbody>
                <RenderTableData />
              </tbody>
            </Table >
          </Card>
        }
        </Content>
      }
    </Container >

  )
}

export default Index;




/*
<div key={balanceRuler._id}>
        <Title style={{ color: "black" }}>{userRuler.title}</Title>
        <RulesRow>
          <Percent>{parseInt(balanceRuler.total * -1 / balance.revenuesTotal * 100) + "%"}</Percent>
          <Percent>~</Percent>
          <Percent>{userRuler.percent + "%"}</Percent>
        </RulesRow>

        <RulesRow>
          <Value>
            {
              (balanceRuler.total * -1).toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
          <Value>
            {
              (userRuler.percent * balance.revenuesTotal / 100)
                .toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
        </RulesRow>
      </div>
*/