import styled from 'styled-components'

export const Container = styled.div`
display: flex;
width: 220px;;
height: 78px;
flex-direction: column;
margin: 0 10px 10px 0;
padding: 10px;
justify-content: center;
background: #FFFFFF;
border-radius: 9px;
`
export const Title = styled.h1`
color: #5C5C5C;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 1.5;
`
export const Value = styled.h2`
font-style: normal;
font-weight: 500;
font-size: 21px;
line-height: 1.5;
`
export const SubTitle = styled.p`
color: grey;
margin: 10px;
font-size: .4em;
font-weight: 400;
@media(min-width: 2560px) {
font-size: .35em;
  }
`

export const Img = styled.img`
height: 35px;
margin: 0 5px 0 0;
`

export const Row = styled.div`
display: flex;
flex-direction: row;
`

export const Col = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`
