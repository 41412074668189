import React, { useContext, useEffect, useState } from 'react'
import { Row, Table, TableRow, TableHead } from './styles'
import { Container, Content, Card } from '../../components/styles'
import BarLeft from '../../components/BarLeft'
import BarTop from '../../components/BarTop'
import { useHistory } from "react-router-dom";
import { UserContext } from '../../contexts/userContext';
import { getTimesToday } from '../../services/apiSaoLuis/times'
import { ReactComponent as ArrowBackIcon } from '../../assets/arrowBackIcon.svg'
import Spinner from '../../components/Spinner'
import moment from 'moment'
moment.updateLocale('pt-br', {
  months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_'),
  weekdaysShort: 'Domingo_Segunda_Terça_Quarta_Quinta_Sexta_Sábado'.split('_'),
});

const Index = () => {
  let history = useHistory();
  const { getUser, user } = useContext(UserContext);
  const [userTimes, setUserTimes] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    !user && getUser()
    listTimesToday()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const listTimesToday = async () => {
    setLoading(true)
    let userTimes = await getTimesToday()
    setUserTimes(userTimes.data)
    setLoading(false)
  }

  const RenderTableData = () => {
    if (userTimes === undefined)
      return null
    else {
      return userTimes.map((time, index) => {
        return (
          <TableRow key={index}>
            <td>{time.user.fullName}</td>
            <td style={{ flex: 2, alignSelf: 'stretch', fontSize: 11 }}>
              {moment.utc(time.date).format("ddd, ")}
              {moment.utc(time.date).format("DD [de] MMM, yyyy")}
            </td>
            <td style={{ flex: 1, fontSize: 11 }}>{time.times[0]?.in}</td>
            <td style={{ flex: 1.2, fontSize: 11 }}>{time.times[1]?.out}</td>
            <td style={{ flex: 1.2, fontSize: 11 }}>{time.times[2]?.in}</td>
            <td style={{ flex: 0.6, fontSize: 11 }}>{time.times[3]?.out}</td>
            <td style={{ flex: 0.6, fontSize: 11 }}>{time.totalTimes}</td>
          </TableRow>
        )
      })
    }
  }

  return (
    <Container>
      <BarLeft active='Home' />
      <BarTop title='Horarios de Hoje' />
      {user && user.role !== 'ADMIN'
        ? null
        : <Content>
          {loading
            ? <Spinner />
            :
        <Card>
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ArrowBackIcon style={{ margin: 12, cursor: 'pointer' }}
              onClick={() => history.replace('/')}
            />
        </Row>
        <Table>
          <TableHead>
            <tr>
              <th>Nome</th>
              <th>Data</th>
              <th>Entrada 1</th>
              <th>Saída 1</th>
              <th>Entrada 2</th>
              <th>Saída 2</th>
              <th>Total de Horas</th>
            </tr>
          </TableHead>
          <tbody>
            <RenderTableData />
          </tbody>
        </Table >
            </Card>
          }
        </Content>
      }
    </Container >

  )
}

export default Index;




/*
<div key={balanceRuler._id}>
        <Title style={{ color: "black" }}>{userRuler.title}</Title>
        <RulesRow>
          <Percent>{parseInt(balanceRuler.total * -1 / balance.revenuesTotal * 100) + "%"}</Percent>
          <Percent>~</Percent>
          <Percent>{userRuler.percent + "%"}</Percent>
        </RulesRow>

        <RulesRow>
          <Value>
            {
              (balanceRuler.total * -1).toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
          <Value>
            {
              (userRuler.percent * balance.revenuesTotal / 100)
                .toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
        </RulesRow>
      </div>
*/