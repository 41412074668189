import React from "react";
import { getUser as getuser, signIn as signin } from '../services/apiSaoLuis/user'

const useUser = () => {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false)

  let getUser = async () => {
    setLoading(true)

    let response = await getuser()
    setUser(response.data)

    setLoading(false)
  }

  let setuserNull = () => {
    setUser(null)
  }

  let signIn = async (cpf, password, onSucess, onFail) => {
    setLoading(true)
    if (cpf.length === 0 || password.length === 0) {
      onFail('Preencha o cpf e senha para continuar!')
      setLoading(false)
    } else {
      try {
        const response = await signin(cpf, password)
        if (response.success) {
          await localStorage.setItem('token', response.token)
          getUser()
          setLoading(false)
          onSucess()
        } else {
          setLoading(false)
          onFail(response.message)

        }
      } catch (err) {
        setLoading(false)
        onFail(err.message);

      }
    }
  }

  return { user, loading, getUser, setuserNull, signIn }
};

export default useUser;