import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react'
import { Row, Button, Table, TableRow, TableHead } from './styles'
import { Container, Content, Card } from '../../components/styles'
import BarLeft from '../../components/BarLeft'
import BarTop from '../../components/BarTop'
import Spinner from '../../components/Spinner'
import { UserContext } from '../../contexts/userContext';
import { ModalContext } from '../../contexts/modalContext'
import { getAllUsers } from '../../services/apiSaoLuis/user'
import UserModal from '../../components/Modal/UserModal'
import InputSearch from '../../components/InputSearch';

const Index = () => {
  const { getUser, user } = useContext(UserContext);
  const { handleModal } = useContext(ModalContext);
  const { addToast } = useToasts();
  const [users, setUsers] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(false)
  let history = useHistory();

  useEffect(() => {
    !user && getUser()
    if (user !== null) {
      if (user && user.role === 'ADMIN') {
        listUsers()
      } else {
        addToast('Você não tem permissão para acessar essa área!', { appearance: 'error', autoDismiss: true })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const listUsers = async () => {
    setLoading(true)
    let users = await getAllUsers()
    setUsers(users.data)
    setFilteredData(users.data)
    setLoading(false)
  }

  const RenderTableData = () => {
    return filteredData && filteredData.map((item, index) => {
      return (
        <TableRow
          key={index}
          onClick={() => {
            history.push("/user", { user: item })
          }}
        >
          <td>{item.fullName}</td>
          <td>{item.cpf}</td>
          <td>{item.pis}</td>
          <td>{item.email}</td>
          <td>{item.phone}</td>
          <td>{item.company}</td>
          <td>{item.isWorking ? 'Sim' : 'Não'}</td>
          <td>{item.role}</td>
        </TableRow>

      )
    })
  }

  const FilterTableData = (name) => {
    const lowercasedValue = name.toLowerCase().trim();
    if (lowercasedValue === "") setFilteredData(users);
    else {
      const filteredData = users.filter(item => {
        return Object.keys(item).some(key =>
          item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setFilteredData(filteredData);
    }
  }
  return (
    <Container>
      <BarLeft menuSelected='Colaboradores' subMenuSelected='Colaboradores' />
      <BarTop title='Colaboradores' />
      {user && user.role !== 'ADMIN'
        ? null
        : <Content>
          {loading
            ? <Spinner />
            : <Card>
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <InputSearch
                  placeholder='Insira um nome para pesquisar'
                  type='text'
                  onChange={(e) => FilterTableData(e.target.value)}
                />
                <Button onClick={() => handleModal(<UserModal listUsers={listUsers} type={'new'} />)}
                  bgColor={'#87B87F'}
                >
                  {'Novo Colaborador'}
                </Button>
              </Row>
              <Table>
                <TableHead>
                  <tr>
                    <th>Nome</th>
                    <th>Cpf</th>
                    <th>Pis</th>
                    <th>Email</th>
                    <th>Telefone</th>
                    <th>Vínculo</th>
                    <th>Em serviço?</th>
                    <th>Nível de Acesso</th>
                  </tr>
                </TableHead>
                <tbody>
                  <RenderTableData />
                </tbody>
              </Table >
            </Card>
          }
        </Content>
      }
    </Container >

  )
}

export default Index;




/*


  const handleDeleteUser = async (user) => {
    var r = window.confirm(`Tem certeza que deseja remover ${user.fullName}`);
    if (r === true) {
      await deleteUser(
        user._id,
        onSuccess => {
          listUsers();
          addToast(onSuccess.message, { appearance: 'success', autoDismiss: true })
        },
        onFail => (addToast(onFail.message, { appearance: 'error', autoDismiss: true }))
      )
    } else {
      return null
    }
  }

 <td>
            <UserButton onClick={() => handleModal(<UserModal listUsers={listUsers} type={'edit'} user={item} />)} bgColor={'#EE9600'}>
              {'Editar'}
            </UserButton>
          </td>
<td>
            <UserButton onClick={() => handleDeleteUser(item)} disabled={loading} bgColor={'#B87F7F'}>
              {'Excluir'}
            </UserButton>
          </td>




<div key={balanceRuler._id}>
        <Title style={{ color: "black" }}>{userRuler.title}</Title>
        <RulesRow>
          <Percent>{parseInt(balanceRuler.total * -1 / balance.revenuesTotal * 100) + "%"}</Percent>
          <Percent>~</Percent>
          <Percent>{userRuler.percent + "%"}</Percent>
        </RulesRow>

        <RulesRow>
          <Value>
            {
              (balanceRuler.total * -1).toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
          <Value>
            {
              (userRuler.percent * balance.revenuesTotal / 100)
                .toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
        </RulesRow>
      </div>
*/