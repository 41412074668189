import React, { useContext, useState } from 'react'
import { Input, Select, Row, Col, Button, Form, Label, FormGroup } from './styles'
import { Container, Content, Card } from '../../components/styles'
import BarLeft from '../../components/BarLeft'
import BarTop from '../../components/BarTop'
import { putUser, deleteUser } from '../../services/apiSaoLuis/user'
import { useToasts } from 'react-toast-notifications';
import { cpfMask, phoneMask } from '../../services/Utils'
import { UserContext } from '../../contexts/userContext'
import { useLocation, useHistory } from 'react-router'

const Index = () => {
  let location = useLocation();
  let history = useHistory();

  const { getUser, user } = useContext(UserContext);
  const [selectedUser] = useState(location.state.user)

  const [_id] = useState(selectedUser ? selectedUser._id : user._id);
  const [fullName, setFullName] = useState(selectedUser ? selectedUser.fullName : user.fullName);
  const [cpf, setCpf] = useState(selectedUser ? selectedUser.cpf : user.cpf);
  const [office, setOffice] = useState('');
  const [workday, setWorkday] = useState('');
  const [isWorking, setIsWorking] = useState(selectedUser?.isWorking !== false ? 'Sim' : 'Não')
  const [email, setEmail] = useState(selectedUser ? selectedUser.email : user.email);
  const [phone, setPhone] = useState(selectedUser ? selectedUser.phone : user.phone);
  const [pis, setPis] = useState(selectedUser ? selectedUser.pis : user.pis);
  const [company, setCompany] = useState(selectedUser
    ? selectedUser.company
    : user.company
  );
  const [password, setPassword] = useState('');
  const { addToast } = useToasts();


  const handleDeleteUser = async () => {
    let response = window.confirm(`Tem certeza que deseja excluir ${fullName}`);
    if (response === true) {
      await deleteUser(
        _id,
        onSuccess =>
          addToast(
            onSuccess.message,
            { appearance: 'success', autoDismiss: true }
          )
        ,
        onFail =>
          addToast(
            onFail.message,
            { appearance: 'error', autoDismiss: true }
          )
      )
    } else {
      return null
    }
  }

  const handleUpdateUser = async () => {
    let data = password === ''
      ? { _id, fullName, cpf, email, phone, pis, company }
      : { _id, fullName, cpf, email, phone, pis, company, password };

    await putUser(data,
      onSuccess => {
        addToast(
          onSuccess.message,
          {
            appearance: 'success',
            autoDismiss: true
          })
        getUser()
      },
      onFail => {
        addToast(
          onFail.message,
          {
            appearance: 'error',
            autoDismiss: true
          })
      }
    )
  }

  return (
    <Container>
      <BarLeft menuSelected='Colaboradores' subMenuSelected='Colaboradores' />
      <BarTop title='Colaborador' />
      <Content>
        <Card style={{ minHeight: '70vh', position: 'relative' }}>
          <Row>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <Col>
                  <Label>Nome Completo</Label>
                  <Input
                    type='text'
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    style={{ width: 330, marginRight: 5 }}
                    disabled={user?.role === 'PUBLIC' ? true : false} />
                </Col>
                <Col>
                  <Label>CPF</Label>
                  <Input
                    type='text'
                    value={cpf}
                    onChange={(e) => setCpf(cpfMask(e.target.value))}
                    style={{ width: 250, marginRight: 5 }}
                    disabled={user?.role === 'PUBLIC' ? true : false} />
                </Col>
                <Col>
                  <Label>Telefone</Label>
                  <Input
                    type='text'
                    value={phone}
                    onChange={(e) => setPhone(phoneMask(e.target.value))}
                    style={{ width: 250 }}
                    disabled={user?.role === 'PUBLIC' ? true : false} />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col>
                  <Label>Email</Label>
                  <Input type='text'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ width: 635, marginRight: 5 }}
                    disabled={user?.role === 'PUBLIC' ? true : false} />
                </Col>
                <Col>
                  <Label>Trabalhando no momento</Label>
                  <Input type='text'
                    value={isWorking}
                    onChange={(e) => setIsWorking(e.target.value)}
                    style={{ width: 200 }}
                    disabled={user?.role === 'PUBLIC' ? true : false} />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col>
                  <Label>Pis</Label>
                  <Input type='text'
                    value={pis}
                    onChange={(e) => setPis(e.target.value)}
                    style={{ width: 400, marginRight: 5 }}
                    disabled={user?.role === 'PUBLIC' ? true : false} />
                </Col>
                <Col>
                  <Label>Cargo</Label>
                  <Input type='text'
                    value={office}
                    onChange={(e) => setOffice(e.target.value)}
                    style={{ width: 435, marginRight: 5 }}
                    disabled={user?.role === 'PUBLIC' ? true : false} />
                </Col>
              </FormGroup>
              <FormGroup>
                <Col>
                  <Label>Empregador</Label>
                  <Select
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    disabled={user?.role === 'PUBLIC' ? true : false}
                    style={{ width: 500, marginRight: 5 }}
                  >
                    < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0001-45</option >
                    < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0001-39</option >
                    < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0005-79</option >
                    < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0004-81</option >
                    < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0003-09</option >
                    < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0005-62</option >
                    < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0006-50</option >
                    < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0002-10</option >
                    < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0002-26</option >
                    < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0003-07</option >
                    < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0007-24</option >
                    < option >Funerária São Luis - Valdemar Targino de Moura - 34.459.988/0001-73</option >
                  </Select>
                </Col>
                <Col>
                  <Label>Jornada de Trabalho</Label>
                  <Input type='text'
                    value={workday}
                    onChange={(e) => setWorkday(e.target.value)}
                    style={{ width: 150, marginRight: 5 }}
                    disabled={user?.role === 'PUBLIC' ? true : false}
                  />
                </Col>
                <Col>
                  <Label>Senha</Label>
                  <Input type='text'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ width: 180 }}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Row>
          <Row>
            <Button onDoubleClick={() => handleUpdateUser()}>Salvar</Button>
            {user.role !== 'ADMIN'
              ? null
              : <Button bgColor={'#EE9600'} onDoubleClick={() => {
                handleDeleteUser()
                history.goBack()
              }}>Excluir</Button>
            }
          </Row>
        </Card>
      </Content>
    </Container >

  )
}

export default Index;




/*
<div key={balanceRuler._id}>
        <Title style={{ color: "black" }}>{userRuler.title}</Title>
        <RulesRow>
          <Percent>{parseInt(balanceRuler.total * -1 / balance.revenuesTotal * 100) + "%"}</Percent>
          <Percent>~</Percent>
          <Percent>{userRuler.percent + "%"}</Percent>
        </RulesRow>

        <RulesRow>
          <Value>
            {
              (balanceRuler.total * -1).toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
          <Value>
            {
              (userRuler.percent * balance.revenuesTotal / 100)
                .toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' })
            }
          </Value>
        </RulesRow>
      </div>
*/