import React, { useState } from "react";
import { Container, Title, Row, TextBold, TextSimple, TopBar, Table, TableRow, Col, TableHead, Img, Button } from './styles'
import moment from 'moment';
import Logo from './../../assets/logo.png'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { useHistory, useLocation } from "react-router-dom";

const PdfPage = () => {
  let location = useLocation();
  let history = useHistory();
  const { from } = location.state || { from: { pathname: "/" } };
  const [loading, setLoading] = useState(false);

const RenderTableData = () => {
  return location.state.times.map((time, index) => {
    let times = []
    for (let index = 0; index < 4; index++) {
      times.push(<td key={index + 10}>{time.times[index] === undefined ? '' : time.times[index].in}</td>)
      times.push(<td key={index + 20}>{time.times[index] === undefined ? '' : time.times[index].out}</td>)
    }

    return (
      <TableRow key={index} >
        <td>
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>
            {moment.utc(time.date).format("ddd")}
          </span>{moment.utc(time.date).format(", D, MMMM, YYYY")}</td>
        {times}
        <td>{time.totalTimes}</td>
      </TableRow>
    )
  })
  };
  
  const getPdf = () => {
    setLoading(true)
    var body = document.getElementById('divIdToPrint')
    html2canvas(body).then(function (canvas) {
      const imgData = canvas.toDataURL('image / png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 5, 2);
      pdf.save(`Relatório de ponto ${location.state.user.fullName}.pdf`);
      setLoading(false)
    });
  }

  return (
    <div className="App">
      <Button
        onClick={() => history.replace(from)}
        bgColor={'#1C3E7E'}>
        Voltar
      </Button>
      <Button
        onClick={getPdf}
        bgColor={'#B87F7F'}>
        {loading ? 'Carregando!' : 'Baixar PDF'}
      </Button>
      <Container id='divIdToPrint'>
        <TopBar >
          <Img src={Logo} />
          <Title>Relatório de ponto individual</Title>
        </TopBar>
        <Row>
          <Col>
            <TextBold>
              Colaborador(a): <TextSimple>
                {location.state.user.fullName}
            </TextSimple>
            </TextBold>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <TextBold>
              Mês:<TextSimple> {moment(location.state.date).format("MMMM, YYYY")}
            </TextSimple>
            </TextBold>
          </Col>
        </Row>
        <Row>
          <Col>
            <TextBold>
              CPF:
             <TextSimple>{location.state.user.cpf}</TextSimple>
            </TextBold>

          </Col>
        </Row>
        <Row>
          <Col>
            <TextBold>
              Empregador:
             <TextSimple> {location.state.user.company} </TextSimple>
            </TextBold>
          </Col>
        </Row>
        <Table >
          <TableHead>
            <tr>
              <th>Data</th>
              <th>Entrada 1</th>
              <th>Saída 1</th>
              <th>Entrada 2</th>
              <th>Saída 2</th>
              <th>Total de Horas</th>
            </tr>
          </TableHead>
          <tbody>
            <RenderTableData />
          </tbody>
        </Table >

        <Row style={{ textAlign: "right" }}>

        </Row>
        <Row>
          <Col style={{ textAlign: "right" }}>
            <TextBold>
              Total de horas trabalhadas no mês:
              <TextSimple>
                {' ' + location.state.sumTimes}
              </TextSimple>
            </TextBold>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "right" }}>
            <TextSimple >
              <br />
              Assinatura:__________________________________________________________
              </TextSimple>
          </Col>
        </Row>
      </Container>

    </div>

  );
}

export default PdfPage;