import { useToasts } from 'react-toast-notifications';
import React, { useContext, useEffect, useState } from 'react'
import { Col, Button, Row, Label, Input, Select, Table, TableRow, TableHead } from './styles'
import { Container, Content, Card } from '../../components/styles'
import BarLeft from '../../components/BarLeft'
import BarTop from '../../components/BarTop'
import { UserContext } from '../../contexts/userContext';
import { getUserByCnpj } from '../../services/apiSaoLuis/user'
import { generateAfd, downloadAfd } from '../../services/apiSaoLuis/times'
import Spinner from '../../components/Spinner';
import moment from 'moment'

const Index = () => {
  const { getUser, user } = useContext(UserContext);
  const { addToast } = useToasts();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState('Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0001-45');
  const [dateI, setDateI] = useState(moment().startOf('month').format("YYYY-MM-DD"));
  const [dateF, setDateF] = useState(moment().endOf('month').format("YYYY-MM-DD"));

  useEffect(() => {
    !user && getUser()
    if (user !== null) {
      if (user && user.role === 'ADMIN') {
        listUsers()
      } else {
        addToast('Você não tem permissão para acessar essa área!', { appearance: 'error', autoDismiss: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, user])

  const listUsers = async () => {
    setLoading(true)
    let users = await getUserByCnpj(company)
    setUsers(users.data)
    setLoading(false)
  }

  const handleGenerateAfd = async () => {
    setLoading(true)
    await generateAfd(
      company,
      users,
      dateI,
      dateF,
      onSucess => {
        addToast(onSucess, { appearance: 'success', autoDismiss: true })
        downloadAfd(company)
      },
      onSucess => {
        addToast(onSucess, { appearance: 'error', autoDismiss: true })
      }
    )
    setLoading(false)
  }

  const RenderTableData = () => {
    return users && users.map((user, index) => {
      return (
        <TableRow key={index}>
          <td>{user.fullName}</td>
        </TableRow>

      )
    })
  }

  return (
    <Container>
      <BarLeft menuSelected='Ponto Eletrônico' subMenuSelected='Gerar AFD' />
      <BarTop title='Gerar AFD' />
      {user && user.role !== 'ADMIN'
        ? null
        :
        <Content>{loading
          ? <Spinner />
          : <Card >
            <Row>
              <Col style={{ width: '100%' }}>
                <Label>CNPJ</Label>
                <Select value={company} onChange={(e) => setCompany(e.target.value)}>
                  < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0001-45</option >
                  < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0001-39</option >
                  < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0005-79</option >
                  < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0004-81</option >
                  < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0003-09</option >
                  < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0005-62</option >
                  < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0006-50</option >
                  < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0002-10</option >
                  < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0002-26</option >
                  < option >Funerária São Luis - Valdemar Targino de Moura - 15.840.736/0003-07</option >
                  < option >Funerária São Luis - Jose Luis Targino de Moura - 04.065.390/0007-24</option >
                </Select>
              </Col>
              <Col style={{ margin: "0px 15px " }}>
                <Label>Data Inicial</Label>
                <Input
                  type='date'
                  defaultValue={dateI}
                  onChange={(e) => setDateI(e.target.value)}
                />
              </Col>
              <Col style={{ margin: "0px 15px " }}>
                <Label>Data Final</Label>
                <Input
                  type='date'
                  defaultValue={dateF}
                  onChange={(e) => setDateF(e.target.value)}
                />
              </Col>
              <Col>
                <Label>{`Gerar Arquivo`}</Label>
                <Button onClick={() => handleGenerateAfd()}
                  bgColor={'#87B87F'}
                >
                  {'Download AFD'}
                </Button>
              </Col>
            </Row>
            <Table>
              <TableHead>
                <tr>
                  <th>Nome do colaborador</th>
                </tr>
              </TableHead>
              <tbody>
                <RenderTableData />
              </tbody>
            </Table >
          </Card>
        }
        </Content>
      }
    </Container >

  )
}

export default Index;