import React from 'react'
import Styled from 'styled-components'

import spinnerGif from '../../assets/spinner.gif'

const Container = Styled.div`
display: flex;
align-items: center;
justify-content: center;
`
const Loader = Styled.img`
width: 38px;
height: 38px;
`

const Spinner = () => {
  return (
    <Container>
      <Loader src={spinnerGif} />
    </Container>
  )
}

export default Spinner;
