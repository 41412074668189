import React, { useState } from 'react';
import styled from 'styled-components';

const Menu = ({ isActive, children, Icon, title }) => {

  const [active, setActive] = useState(isActive)

  return (
    <Container >
      <Main onClick={() => setActive(!active)} active={active} >
        <Icon
          style={{ height: '15px', margin: '0 10px' }}
          fill={active ? "#fafafa" : "#A0A1B6"} />
        <Text active={active}>{title}</Text>
      </Main>
      {active ? children : null}
    </Container>
  )
}

export default Menu

const Container = styled.div`
user-select: none;
`
const Main = styled.div`
cursor: pointer;
display: flex;
height: 40px;
background: ${(props) => props.active ? "rgba(255, 255, 255, 0.04)" : ""};
align-items: center;
&:hover {
  background: rgba(255, 255, 255, 0.04);
}
`
const Text = styled.div`
font-style: normal;
font-weight: normal;
font-size: 12px;
color: ${(props) => props.active ? "#fafafa" : "#A0A1B6"};
`
