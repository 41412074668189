import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { Container, ContainerButtons, Img, ContainerForm, Button, Message } from './styles'
import Spinner from '../../components/Spinner'
import Logo from '../../assets/logo.svg'
import Input from './Input'
import { UserContext } from '../../contexts/userContext';
import { cpfMask } from '../../services/Utils';
import { COLORS } from '../../components/styles';
import userIcon from '../../assets/userIcon.svg'
import passIcon from '../../assets/passIcon.svg'

const LogIn = () => {
  let history = useHistory();
  let location = useLocation();
  let { signIn, loading } = useContext(UserContext);

  const { from } = location.state || { from: { pathname: "/times" } };
  const [cpf, setCpf] = useState('')
  const [password, setPassword] = useState('')
  const [msg, setMsg] = useState('')

  const handle = (e) => {
    if (e.keyCode === 13) {
      signIn()
    }
    e.preventDefault()
  }
  return (
    <Container style={{ backgroundColor: COLORS.BLUE }}>
      <ContainerForm onSubmit={handle}>
        <Img src={Logo} />
        <Input
          icon={userIcon}
          placeholder={'Insira seu CPF'}
          value={cpf}
          onChange={(e) => setCpf(cpfMask(e.target.value))}
        />
        <Input
          icon={passIcon}
          type='password'
          placeholder={'Insira sua senha'}
          onChange={(e) => setPassword(e.target.value)}
          onkeypress={(e) => handle(e)}
        />
        <ContainerButtons>
          {loading ? <Spinner />
            : <Button type="submit"
              style={{ width: "240px" }}
              onClick={(e) => {
                e.preventDefault()
                signIn(
                  cpf,
                  password,
                  onSucess => { history.replace(from) },
                  onFail => { setMsg(onFail) }
                )
              }}
              inputColor={COLORS.GREEN}
            > Entrar
          </Button>
          }
        </ContainerButtons>
        <Message>{msg}</Message>
      </ContainerForm>
    </Container>
  )
}

export default LogIn
