import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './Auth'

import LogIn from './pages/LogIn'
import Times from './pages/Times'
import TimesToday from './pages/TimesToday'
import Home from './pages/Home'
import TimesBank from './pages/TimesBank'
import TimesAfd from './pages/TimesAfd'
import TimesRequests from './pages/TimesRequests'
import UserTimes from './pages/UserTimes'
import Users from './pages/Users'
import User from './pages/User'
import Pdf from './components/Pdf'

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact={true} component={LogIn} />
        <Route path="/pdf" exact={true} component={Pdf} />
        <PrivateRoute path="/" exact={true} component={Home} />
        <PrivateRoute path="/times" exact={true} component={Times} />
        <PrivateRoute path="/times/today" exact={true} component={TimesToday} />
        <PrivateRoute path="/times/requests" exact={true} component={TimesRequests} />
        <PrivateRoute path="/times/bank" exact={true} component={TimesBank} />
        <PrivateRoute path="/times/manage" exact={true} component={UserTimes} />
        <PrivateRoute path="/times/afd" exact={true} component={TimesAfd} />
        <PrivateRoute path="/users" exact={true} component={Users} />
        <PrivateRoute path="/user" exact={true} component={User} />
      </Switch>
    </ Router>
  );
}

/** /<Route path="/signup" exact={true} component={SignUp} />
       // <PrivateRoute path="/" exact={true} component={FixedRevenues} />
       // <PrivateRoute path="/FixedRevenues/Transactions" exact={true} component={FixedRevenuesTransacitons} />
       // <PrivateRoute path="/CriptoCoins" exact={true} component={CriptoCoins} /> */