import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { Container, TitleBar, ExitDiv, Text, Col } from './styles'
import { UserContext } from '../../contexts/userContext';
import { ReactComponent as AlertIcon } from '../../assets/alertIcon.svg'
import { ReactComponent as ProfileIcon } from '../../assets/profileIcon.svg'
import { ReactComponent as ExitIcon } from '../../assets/exitIcon.svg'
import { countPendingTimeRequests } from '../../services/apiSaoLuis/timeRequest';

const Bartop = ({ title, }) => {
  const { setuserNull, user, loading } = useContext(UserContext);
  const [pendingsRequestTimes, setPendingsRequestTimes] = useState('')
  let history = useHistory();

  const logOut = () => {
    localStorage.removeItem('token')
    setuserNull()
    history.replace("/login")
  }

  useEffect(() => {
    if (user?.role === 'ADMIN') {
      CountPendingsTimeRequest()
    }
  }, [user])

  async function CountPendingsTimeRequest() {
    await countPendingTimeRequests(onSuccess => {
      setPendingsRequestTimes(onSuccess.data?.count || '0')
    })
  }

  return (
    <Container>
      <TitleBar>{title}</TitleBar>
      <ExitDiv>
        <Col style={{ position: 'relative' }}>
          {user?.role === 'ADMIN'
            ? <div>
              <AlertIcon style={{ marginTop: 10, marginInline: 5, cursor: 'pointer' }} />
              <div style={{
                position: 'absolute',
                top: 11,
                left: 20,
                fontSize: 12,
                fontWeight: 'bold',
                color: 'white'
              }}>
                {pendingsRequestTimes}
              </div>
            </div>
            : null
          }

        </Col>
        <Col>
          <ProfileIcon
            onClick={() => {
              history.push("/user", { user: user })
            }}
            style={{
              marginLeft: 10,
              marginRight: 5,
              marginTop: 10,
              cursor: 'pointer'
            }} />
        </Col>
        <Col onClick={() => {
          history.push("/user", { user: user })
        }} style={{ marginTop: 8, marginRight: 10, cursor: 'pointer' }} >
          {loading
            ? <Text>Carregando...</Text>
            : <Text
              style={{ fontStyle: 'normal', fontWeight: 'bold' }}>
              {`Olá, ${user && user.fullName.split(' ').slice(0, 2).join(' ')}`}
            </Text>
          }
          <Text>...</Text>
        </Col>
        <Col>
          <ExitIcon style={{ marginTop: 14, marginLeft: 10, cursor: 'pointer' }} onClick={() => { logOut() }} />
        </Col>
      </ExitDiv>
    </Container>
  )
}

export default Bartop;
