import styled from 'styled-components'

export const COLORS = {
  'BLUE': '#242342',
  'GREY': '#A0A1B6',
  'WHITE': '#FFFFFF',
  'BACKGROUND': '#F5F5F5',
  'BLACK': '#000000',
  'ORANGE': '#EE9600',
  'LIGHTBLUE': '#039BE5',
  'GREEN': '#87B87F',
}


export const Container = styled.div`
background-color: ${COLORS.BACKGROUND};
height: 100vh;
align-items: center;
justify-content: center;
flex-direction: column;
`
export const Content = styled.div`
position: absolute;
top: 55px;
left: 13vw;
width: 87vw;
display: flex;
flex: 1;
padding: 0px 20px;
min-height: 87vh;
background-color: ${COLORS.BACKGROUND};
flex-direction: column;
`
export const Card = styled.div`
background-color: ${COLORS.WHITE};
padding: 10px;
border-radius: 9px;
`