import React, { useState } from 'react'
import styled from 'styled-components'

const SubMenu = ({ isActive, Icon, title }) => {

  const [active, setActive] = useState(isActive)

  return (
    <Container onClick={() => setActive(!active)}>
      <Icon
        style={{ height: '15px', margin: '0 10px' }}
        fill={active ? "#fafafa" : "#A0A1B6"} />
      <Text active={active} >{title}</Text>
    </Container>
  )
}

export default SubMenu

export const Container = styled.div`
cursor: pointer;
display: flex;
height: 40px;
padding-left: 10px;;
align-items: center;
&:hover {
  background: rgba(255, 255, 255, 0.04);
}
`
const Text = styled.div`
font-style: normal;
font-weight: normal;
font-size: 12px;
color: ${(props) => props.active ? "#fafafa" : "#A0A1B6"};
`