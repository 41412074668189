import React from 'react'
import {
  Link
} from "react-router-dom";
import {
  Container,
  Img,
} from './styles'
import logoMenu from '../../assets/logoMenu.svg'
import Menu from './Menu'
import SubMenu from './SubMenu'
import { ReactComponent as homeIcon } from '../../assets/homeIcon.svg';
import { ReactComponent as clockIcon } from '../../assets/clockIcon.svg';
import { ReactComponent as messageIcon } from '../../assets/messageIcon.svg';
import { ReactComponent as hoursBankIcon } from '../../assets/hoursBankIcon.svg';
import { ReactComponent as pointsIcon } from '../../assets/pointsIcon.svg';
import { ReactComponent as personIcon } from '../../assets/personIcon.svg';
import { ReactComponent as exportIcon } from '../../assets/exportIcon.svg';
const BarLeft = ({ menuSelected, subMenuSelected }) => {

  return (
    <Container>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Img src={logoMenu} />
      </div>
      <Link to='/'>
        <Menu
          Icon={homeIcon}
          isActive={menuSelected === 'Home' ? true : false}
          title={'Home'}>
        </Menu>
      </Link>
      <Menu
        Icon={pointsIcon}
        isActive={menuSelected === 'Ponto Eletrônico' ? true : false}
        title={'Ponto eletrônico'}>
        <Link to='/times'>
          <SubMenu
            Icon={clockIcon}
            isActive={subMenuSelected === 'Registro de ponto' ? true : false}
            title={'Registro de ponto'} />
        </Link>
        <Link to='/times/requests'>
          <SubMenu
            Icon={messageIcon}
            isActive={subMenuSelected === 'Solicitações de ponto' ? true : false}
            title={'Solicitações de ponto'} />
        </Link>
        <Link to='/times/bank'>
          <SubMenu
            Icon={hoursBankIcon}
            isActive={subMenuSelected === 'Banco de Horas' ? true : false}
            title={'Banco de Horas'} />
        </Link>
        <Link to='/times/afd'>
          <SubMenu
            Icon={exportIcon}
            isActive={subMenuSelected === 'Gerar AFD' ? true : false}
            title={'Gerar AFD'} />
        </Link>
      </Menu>
      <Link to='/users'>
        <Menu
          Icon={personIcon}
          isActive={menuSelected === 'Colaboradores' ? true : false}
          title={'Colaboradores'}>
        </Menu>
      </Link>
    </Container >
  )
}

export default BarLeft;
/**
      <Link to='/'>
        <Button active={(active === 'Ações e Fiis - EUA') ? true : false}>
          Ações e Fiis - EUA
        </Button>
      </Link>
      <Link to='/'>
        <Button active={(active === 'Criptos') ? true : false}>
          Criptos
        </Button>
      </Link>

 <User>
          {loading
            ? 'Carregando...'
            : `Olá, ${user && user.fullName.split(' ').slice(0, 2).join(' ')}`
          }
        </User>
 <Link to='/'>
        <Button active={(active === 'Home') ? true : false}>
          Home
        </Button>
      </Link>
      <Link to='/times'>
        <Button active={(active === 'Ponto Eletrônico Online') ? true : false}>
          Ponto Eletrônico Online
        </Button>
      </Link>
      <Link to='/timesManager'>
        <Button active={(active === 'Gerenciamento de Pontos') ? true : false}>
          Gerenciamento de Pontos
        </Button>
      </Link>
      <Link to='/users'>
        <Button active={(active === 'Colaboradores') ? true : false}>
          Colaboradores
        </Button>
      </Link>

*/