import Routes from './Routes'
import { UserProvider } from './contexts/userContext';
import { ModalProvider } from './contexts/modalContext';
import { ToastProvider } from 'react-toast-notifications';

function App() {
  return (
    <ToastProvider PlacementType='bottom-left' >
      <ModalProvider>
        <UserProvider>
          <Routes />
        </UserProvider>
      </ModalProvider>
    </ToastProvider>
  );
}

export default App;
