import axios from 'axios'
import { BASE_URL } from '../../config/config.json'

export const createTimeRequest = async (
  time,
  type,
  status,
  motivation,
  onSuccess,
  onFail
) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/timeRequest`,
      method: 'post',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: { timeId: time._id, date: time.date, times: time.times, type, status, motivation }
    })
    return onSuccess(response.data)
  } catch (error) {
    return console.log(error)
  }
}

export const getAllTimeRequests = async (status, onSuccess) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/timeRequest/list/${status}`,
      method: 'get',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: {}
    })
    return onSuccess(response.data)
  } catch (error) {
    return console.log(error)
  }
}

export const getUserTimeRequests = async (status, onSuccess) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/timeRequest/list/user/${status}`,
      method: 'get',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: {}
    })
    return onSuccess(response.data)
  } catch (error) {
    return console.log(error)
  }
}

export const updateTimeRequest = async (item, status, onSuccess) => {
  const { _id, date, timeId, type, times, motivation } = item
  try {
    var response = await axios({
      url: `${BASE_URL}/timeRequest/${_id}`,
      method: 'put',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: { date, timeId, type, times, status, motivation }
    })
    return onSuccess(response.data)
  } catch (error) {
    return console.log(error)
  }
}

export const countPendingTimeRequests = async (onSuccess) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/timeRequest/Countpendings`,
      method: 'get',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: {}
    })
    return onSuccess(response.data)
  } catch (error) {
    return console.log(error)
  }
}