import React, { useContext, useEffect, useState, useCallback } from 'react'
import { getUsersWorking, getUsersVacation } from '../../services/apiSaoLuis/user'
import { BASE_URL } from '../../config/config.json'
import { UserContext } from '../../contexts/userContext';
import { useToasts } from 'react-toast-notifications';
import { Container, Content, Row } from './styles';
import io from 'socket.io-client';
import BarLeft from '../../components/BarLeft'
import BarTop from '../../components/BarTop'
import Resume from '../../components/Resume'
import Spinner from '../../components/Spinner'

import userResumeIcon from '../../assets/userResumeIcon.svg'

const Index = () => {
  const [usersWorking, setUsersWorking] = useState('');
  const [usersVacation, setUsersVacation] = useState('');
  const [loading, setLoading] = useState(false)
  const { getUser, user } = useContext(UserContext);
  const { addToast } = useToasts();

  useEffect(() => {
    !user && getUser()
    if (user !== null) {
      if (user && user.role === 'ADMIN') {
        getUserResumes()
      } else {
        addToast('Você não tem permissão para acessar essa área!', { appearance: 'error', autoDismiss: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])



  useCallback(() => {
    var registerToSocket = () => {
      const socket = io(BASE_URL, { transports: ['websocket', 'polling', 'flashsocket'] })

      socket.on('time', message => {
        addToast(message, { appearance: 'success', autoDismiss: true })
      })
    }
    if (user && user.role === 'ADMIN') {
      registerToSocket()
    }
  }, [])


  const getUserResumes = async () => {
    setLoading(true)

    let usersResume = await getUsersWorking()
    setUsersWorking(usersResume.data)

    let usersVacation = await getUsersVacation()
    setUsersVacation(usersVacation.data)

    setLoading(false)
  }

  return (
    <Container>
      <BarLeft menuSelected='Home' subMenuSelected='Home' />
      <BarTop title='Home' />
      {user && user.role !== 'ADMIN'
        ? null
        : <Content>
          {loading
            ? <Spinner />
            : <Row>
              <Resume
                icon={userResumeIcon}
                title={'Colaboradores em serviço'}
                value={usersWorking.usersWorking}
                link={"/times/today"}
              ></Resume>
              <Resume
                title={'Colaboradores de férias no Mês'}
                value={usersVacation.vacationInMonth}
              />
            </Row>
          }
        </Content>
      }
    </Container >

  )
}

export default Index;