import axios from 'axios'
import { BASE_URL } from '../../config/config.json'

export const signIn = async (cpf, password) => {
  try {
    var response = await axios.post(`${BASE_URL}/users/login`, {
      cpf: cpf,
      password: password
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
};

export const getUser = async () => {
  try {
    var response = await axios.get(`${BASE_URL}/users`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
}

export const getAllUsers = async () => {
  try {
    var response = await axios.get(`${BASE_URL}/users/all`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
}

export const putUser = async (data, onSuccess, onFail) => {
  try {
    var response = await axios(
      {
        url: `${BASE_URL}/users/${data._id}`,
        method: 'put',
        timeout: 8000,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        data: data
      }
    )
    return onSuccess(response.data)
  } catch (error) {
    return onFail(error.response.data)
  }
}

export const deleteUser = async (_id, onSuccess, onFail) => {
  try {
    var response = await axios.delete(`${BASE_URL}/users/${_id}`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    return onSuccess(response.data)
  } catch (error) {
    return onFail(error.response.data)
  }
}

export const postUser = async (
  fullName,
  cpf,
  email,
  pis,
  phone,
  company,
  role,
  pass,
  onSuccess
) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/users`,
      method: 'post',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: { fullName, cpf, email, pis, phone, company, role, password: pass }
    })
    return onSuccess(response.data)
  } catch (error) {
    return console.log(error)
  }
}

export const getUsersWorking = async () => {
  try {
    var response = await axios.get(`${BASE_URL}/users/usersWorking`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
}

export const getUsersVacation = async () => {
  try {
    var response = await axios.get(`${BASE_URL}/users/usersVacation`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
}

export const getUserTime = async (year, month, _id) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/userTimes/timesInMonth/${_id}`,
      method: 'post',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: { year, month }
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
}

export const putUserVacation = async (dates, _id, onSuccess) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/users/vacations/${_id}`,
      method: 'put',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: dates
    })
    return onSuccess(response.data)
  } catch (error) {
    return console.log(error)
  }
}

export const getUserByCnpj = async (company) => {
  try {
    var response = await axios({
      url: `${BASE_URL}/users/byCnpj`,
      method: 'post',
      timeout: 8000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: { company: company }
    })
    return response.data
  } catch (error) {
    return (error.response.data)
  }
}