import styled from 'styled-components'
import { COLORS } from '../styles'

export const Container = styled.div`
flex-direction: row;
background-color: ${COLORS.BACKGROUND};
z-index: 99;
position: fixed;
display: flex;
align-items: center;
justify-content: space-between;
width: 87vw;
height: 55px;
right: 0px;
top: 0px;
padding: 0 20px 0 20px;
`
export const ExitDiv = styled.div`
display: flex;
color: black;
flex-direction: row;
`

export const TitleBar = styled.div`
font-size: 18px;
font-style: normal;
font-weight: bold;
@media(min-width: 2560px) {
font-size: .6em;
  }
`

export const Text = styled.p`
font-size: 14px;
align-items: center;

`

export const ExitBtn = styled.img`
margin-left: 10px;
height: 3vh;
`
export const Col = styled.div`
flex-direction: column;
align-items: center;
`
